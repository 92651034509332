@import './../../styles/variables.scss';

$contact-color-grey:#666666;
.contact-form-info{
    width: 74.358974358974359%;
    margin: 0 ;
    
  }
.contact-form-black-wrapper{
    background-color: $color-dark;
    h2{
        color: $color-light!important;
    }
    .email-success{
        p.text-smallest{
            color: #fff !important;
        }
    }


    //FOCUS ANIMATION WHITE
    input ~ .focus-border:before,
    input ~ .focus-border:after{background-color: #fff !important;}
    input ~ .focus-border i:before,
    input ~ .focus-border i:after{background-color: #fff !important;}
    textarea ~ .focus-border:before,
    textarea ~ .focus-border:after{background-color: #fff !important;}
    textarea ~ .focus-border i:before,
    textarea ~ .focus-border i:after{background-color: #fff !important;}
    //................................


    input,textarea{
        color: #E2E2E2!important
    }
    input::placeholder,textarea::placeholder{
        color: #E2E2E2!important
    }

    input:focus {
        outline: none;
        color: #fff !important;
        opacity: 1 !important;
    }
    textarea:focus {
        outline: none;
        color: #fff !important;
        opacity: 1 !important;
    }

}
.contact-form-box{
    width: $content-width;
    margin: 0 auto;
    @media (max-width:1000px) {
        width: $content-width-responsive;
    }
}

.contact-form-wrapper{
    width: 74.358974358974359%;
    margin: 0 auto;
    padding: $space-normal 0;
    .contact-form-info{
        h2{
            color: $color-dark;
            margin: 0;
            margin-bottom: $space-small;
        }
        @media (max-width:1300px) {
            width: 80%;
        }
    
        @media (max-width:1160px) {
            width: 90%;
        }
    
        @media (max-width:900px) {
            width: 100%;
        }
    }
    .contact-info{
        color: #666666;
        margin: 0;
        padding-bottom: $space-small;
        a{
            color: $color-red;
        }
    }
    .contact-form{
        display: flex;
        flex-wrap: wrap;

        input[type="file"]{
            display: none;
        }
        
        .flex-top{
            display: flex;
            width: 100%;
            padding-bottom: $space-small/2;
            justify-content: space-between;
            .input-text{
                position: relative;
                width: 49%;
            }

            //FOCUS ANIMATION
            input ~ .focus-border:before,
            input ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 1px; background-color: #000000; transition: 0.3s;}
            input ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
            input ~ .focus-border i:before,
            input ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 1px; height: 0; background-color: #000000; transition: 0.4s;}
            input ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
            input:focus ~ .focus-border:before,
            input:focus ~ .focus-border:after{width: 100%; transition: 0.3s;}
            input:focus ~ .focus-border i:before,
            input:focus ~ .focus-border i:after{height: 100%; transition: 0.4s;}
            //................................


            input[type="text"],
            input[type="email"]{
                
                width: 100%;
                color: #666666;
                background: transparent;
                border: 1px solid #E2E2E2;
                opacity: 0.6;
                outline-color: #212427;
                outline-width: 1.5px;
                box-sizing: border-box;
                font-size: 1rem;
                letter-spacing: 0;

                padding: $space-small - 0.625 0 $space-small - 0.625 $space-small;
                @media (max-width:700px) {
                    padding: $space-small - 0.825 0 $space-small - 0.825 $space-small - 0.325;
                    font-size: .9rem;
                }
                @media (max-width:600px) {
                    padding: $space-small - 0.925 0 $space-small - 0.925 $space-small - 0.625;
                    font-size: .8rem;
                }
                @media (max-width:500px) {
                    padding: $space-small - 1.125 0 $space-small - 1.125 $space-small - 0.825;
                    font-size: .7rem;
                }
                &.error {
                    background-image: url("../../assets/Alert\ Triangle.svg");
                    background-repeat: no-repeat;
                    background-position-x: 98%;
                    background-position-y: center;
                    background-size: 20px;
                    border: 1px solid red;
                    animation: shake .5s cubic-bezier(.36,.07,.19,.97) both;
                }
            }
            input[type="text"]{
                margin-right: $space-small/2;
                @media (max-width:700px) {
                    margin-right: $space-small/3;
                }
                @media (max-width:600px) {
                    margin-right: $space-small/3;
                }
            }
            @media (max-width:700px) {
                padding-bottom: $space-small/3;
            }
            @media (max-width:600px) {
                padding-bottom: $space-small/3;
            }
        }
        .input-textarea{
            position: relative;
            width: 100%;
            margin-bottom: $space-small/2;
            height: 10.125rem;
            @media (max-width:700px) {
                height: 8.125rem;
            }
            @media (max-width:600px) {
                height: 6.125rem;
            }
        }
        input:focus {
            outline: none;
            color: #000;
            opacity: 1;
        }


        //FOCUS ANIMATION
        textarea ~ .focus-border:before,
        textarea ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 1px; background-color: #000000; transition: 0.3s;}
        textarea ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
        textarea ~ .focus-border i:before,
        textarea ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 1px; height: 0; background-color: #000000; transition: 0.4s;}
        textarea ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
        textarea:focus ~ .focus-border:before,
        textarea:focus ~ .focus-border:after{width: 100%; transition: 0.3s;}
        textarea:focus ~ .focus-border i:before,
        textarea:focus ~ .focus-border i:after{height: 100%; transition: 0.4s;}
        //............................



        textarea{
            height: 10.125rem;
            width: 100%;
            font-family: 'Strelsans';
            color: #666666;
            background: transparent;
            border: 1px solid #E2E2E2;
            opacity: 0.6;
            resize: none;

            font-size: 1rem;
            letter-spacing: 0;
            box-sizing: border-box;
            padding: $space-small - 0.625 0 $space-small - 0.625 $space-small;
            @media (max-width:700px) {
                padding: $space-small - 0.825 0 $space-small - 0.825 $space-small - 0.325;
                font-size: .9rem;
                margin-bottom: $space-small/3;
                height: 8.125rem;
            }
            @media (max-width:600px) {
                padding: $space-small - 0.925 0 $space-small - 0.925 $space-small - 0.625;
                font-size: .8rem;
                margin-bottom: $space-small/3;
                height: 6.125rem;
            }
            @media (max-width:500px) {
                padding: $space-small - 1.125 0 $space-small - 1.125 $space-small - 0.825;
                font-size: .7rem;
                margin-bottom: $space-small/3.5;
            }
            &.error {
                background-image: url("../../assets/Alert\ Triangle.svg");
                background-repeat: no-repeat;
                background-position-x: 99%;
                background-position-y: 10%;
                background-size: 20px;
                border: 1px solid red;
                animation: shake .5s cubic-bezier(.36,.07,.19,.97) both;
            }
        }
        textarea:focus {
            outline: none;
            color: #000;
            opacity: 1;
        }
        .flex-bottom{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            label{
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: .3s;
                p{
                    display: inline-block;
                    margin: 0;
                    color: $color-red;
                    width: unset;
                    font-size: .875rem;
                    letter-spacing: 0;
                }
                img{
                    margin-right: $space-small/2;
                }
            }
            label:hover,
            label:focus{
                transform: translateX(5px);
            }
            button{
                color: #FA124F;
                background: #212427;
                outline: none;
                border: none;
                cursor: pointer;
                transition: .3s;
    
                font-size: $small-text-font-size;
                line-height: $small-text-line-height;
                letter-spacing: 0;

                padding: $space-small/2 $space-normal;
                @media (max-width:700px) {
                    padding: $space-small/3 $space-normal/1.5;
                    font-size: 1rem;
                }
                @media (max-width:600px) {
                    padding: $space-small/3 $space-normal/1.5;
                }
                @media (max-width:500px) {
                    padding: $space-small/4 $space-normal/2;
                }
            }
            button:hover,
            button:focus{
                background: #FA124F;
                color: #212427;
            }
        }
    }
    @media (max-width:1300px) {
        width: 80%;
    }

    @media (max-width:1160px) {
        width: 90%;
    }

    @media (max-width:900px) {
        width: 100%;
    }
    @media (max-width:700px) {
        padding: $space-small 0;
    }
}

.form-fade{
    z-index: -1;
    position: absolute;
    animation: fade-out 2s;
}
.email-success{
    transition: all 1s;
    display: none;
    img{
        width: 30px;
    }
    span{
        color: #000000;
        margin: 0;
        width: 100%;
    }
}
.email-success-visible{
    display: block !important;
    animation: swing-in-top-fwd 2s;
    div{
        margin-bottom: $space-small;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p{
            width: 100%;
            padding: 0 15px;
            color: #666666;
        }
    }
}


@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  