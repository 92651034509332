@import './../../styles/variables.scss';

.contact-page{
    
    width: $content-width;
    margin: 0 auto;
    padding-top: $space-normal + 3.25;
    @media (max-width:1000px) {
        width: $content-width-responsive;
        padding-top: $space-normal + 3.25;
    }
    @media (max-width:700px) {
        padding-top: $space-normal + 3.25;
    }

    .contact-page-info{
        width: 74.358974358974359%;
        margin: 0 auto;
        h2{
            color: $color-dark;
            margin: 0;
            margin-bottom: $space-small;
        }
        p{
            color: #666666;
            margin: 0;
            padding-bottom: $space-small;
            a{
                color: $color-red;
            }
        }
        @media (max-width:1300px) {
            width: 80%;
        }
    
        @media (max-width:1160px) {
            width: 90%;
        }
    
        @media (max-width:900px) {
            width: 100%;
        }
      }
}