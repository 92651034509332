
@import './../../styles/variables.scss';

.lang-active{
  color: #ffffff;
}
.lang-bttn:nth-child(2){
  margin-left: $space-small;
  @media (max-width:580px){
    margin-left: $space-small/2;
  }
}

header{
  width: $content-width;
  margin: 0 auto;
  height:5.25rem ;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  .contact{
    display: inline-block;
    margin-right: 1.88rem;
    text-decoration: none;
    color: $color-red;
    font-size: 0.88rem;
    z-index: 1005;
  }
  .contact:after {
    content: '';
    display: block;
    border-bottom: .09375rem solid #FA124F;
    width: 0;
    position: relative;
    transition: .3s cubic-bezier(.22, .68, 0, 1.1);
  }
  .contact:hover:after {
    width: 100%;
  }
  .projects{
  position: relative;
  right: -3.81rem;
    width: 56rem;
  height: 19rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  }
  .project{
    margin-top: 20px;
    margin-right: 1.88rem;
    width: 16.88rem;
    flex-shrink: 0;
    height: 14.38rem;
    transition: 0.3s;
    
  }
  .project:hover{
    transform: scale(1.05);
    z-index: 500;
  }
  .surglogs{
    background:#51B5F3;
    img{
      position: relative;
      z-index: 1;
      top: -0.5rem;
      right: -.69rem;
      width: 16.82rem;
      margin: 0;
    }
    p{
      text-align: center;
      font-weight: bold;
      letter-spacing: -0.01rem;
      color: $color-light;
      font-size: 0.9rem;
      position: relative;
      z-index: 1001;
      top: -6.38rem;
      margin: 0;
    }
  }
  .b2b{
    background: #8C71C0;
    img{
      width: 19.38rem;
      position: relative;
      top: -3.1rem;
      left: -1.25rem;
    }
    p{
      text-align: center;
      font-weight: bold;
      letter-spacing: -0.01rem;
      color: $color-light;
      font-size: 0.9rem;
      position: relative;
      z-index: 1001;
      top: -6.7rem;
      margin: 0;
    }
  }
  .icewrap{
    background: linear-gradient(180deg, #00B5FF 0%, #0094FF 100%);
    #icewrap-first{
      position: relative;
      width: 3.88rem;
      right: 11.75rem;
      top: 1.25rem;
    }
    #icewrap-secound{
      position: relative;
      width: 14rem;
      top: 1.44rem;
      left: 1.25rem;
    }
    p{
      text-align: center;
      font-weight: bold;
      letter-spacing: -0.01rem;
      color: $color-light;
      font-size: 0.9rem;
      position: relative;
      z-index: 1001;
      top: 2.5rem;
      margin: 0;
    }
  }
  .DennikN{
    background:#c02134;
    img:nth-child(1){
      position: relative;
      top: -1rem;
      width: 12rem;
    }

    img:nth-child(2){
      position: relative;
      left: -3.95rem;
      top: -4rem;
      width: 9rem;
    }
  }
  .logo{
    z-index: 1009;
    width: 4.38rem;
    margin-right: auto;
  }
  
}
.projects::-webkit-scrollbar{
  background: transparent;
  height: 0.5rem;
  border-radius: 10px;
}
.projects::-webkit-scrollbar-track{
  background: rgba(213, 213, 213, 0.25);
  border-radius: 10px;
}
.projects::-webkit-scrollbar-thumb
{
  background:$color-red;
  border-radius: 10px;
  opacity: 0.2;
}
 nav{
    a
    {
      text-decoration: none;
      color: $color-grey;
      height: 11px;
      transition: color 0.3s ease;
    }
    a:hover
    {
      color: $color-red;
    }
    
    
 }
 #menuToggle
 {
   width: 23.5px;
   display: block;
   right: 9.8rem;
   top: 2rem;
   z-index: 1003;
   -webkit-user-select: none;
   user-select: none;
 }
 #menuToggle input
 {
   display: block;
   width: 23.5px;
   position: absolute;
   padding: 0;
   margin: 0;
   cursor: pointer;
   opacity: 0; 
   z-index: 1006;
   -webkit-touch-callout: none;
 }
 #menuToggle span
 {
   display: block;
   width: 1.5rem;
   height: 0.13rem;
   position: relative;
   background: $color-red;
   border-radius: 3px;
   
   z-index: 1005;
   
   transform-origin: 4px 0px;
   
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
               opacity 0.55s ease;
 }
 
 #menuToggle span:first-child
 {
   transform-origin: 0% 0%;
 }
 #menuToggle span:nth-last-child(2)
 {
   transform-origin: 0% 100%;
 }
 #menuToggle span:nth-child(2){
   margin-bottom: 5px;
   height: 2px;
 }
 #menuToggle span:nth-child(3){
   height: 2px;
}
 #menuToggle input:checked ~ span
 {
   opacity: 1;
   transform: rotate(45deg) translate(-3px, -1px);
   background: $color-light;
 }
 #menuToggle input:checked ~ span:nth-child(3)
 {
   opacity: 1;
   transform: rotate(-45deg)translate(-3px,-1px);

 }
 #menu
 {
   position: fixed;
   top: 0;
   width: 62rem;
   height: 100vh;
   margin: -100px 0 0 0;
   padding-top: 125px;
   right: 0;
   z-index: 1004;
   background: #000000;
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
   transform-origin: 0% 0%;
   transform: translate(100%, 0);
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
   li:nth-child(1){
     padding-top:5rem;
   }
 }
 
 #menu li
 {
   padding-top: 3.75rem;
   text-align: right;
   font-size: 1rem;
   margin-right: 8.44rem;
 }
 #overlay{
  position: fixed;
   top: 0;
   width: 20rem;
   height: 100vh;
   padding-top: 125px;
   left: 0;
   opacity: 0.8;
   background: #000000;
   list-style-type: none;
   -webkit-font-smoothing: antialiased;
   transform-origin: 0% 0%;
   transform: translate(-100%, 0);
   z-index: 1003  ;
   transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
 }
 #menuToggle input:checked ~ ul
 {
   transform: scale(1.0, 1.0);
   opacity: 1;
 }
 #menuToggle input:checked ~ div
 {
   transform: scale(1.3, 1.0);
 }
 #menuToggle input:checked + div
 {
   position: fixed;
   background: orange !important;
 }

 .header-fix{
  position: fixed;
  z-index: 1000;
}
@media(min-width: 1920px){
  header{
    width: 1565px;
  }
}
@media (max-width: 1000px){
 header{
   width: $content-width-responsive;
 }
}
@media (max-width: 950px){
  div .projects{
    left: 10rem;
     width: 70%;
     padding: 0;
     margin: 0 auto;
  }
}
@media (max-width: 740px){
  div .projects{
    left: 15rem;
     width: 50%;
     
  }
}
 @media (max-width: 1032px){
   #overlay{
     display: none;
   }
 }
 //MOBILE DEVICES
 @media (max-width: 580px){
   
  #menu li:nth-child(1) {
    
    padding-top: 0;
    margin-top: -3rem;
  }
  #none{
    display: none;
  }
   #menu{
     width: 100%;
     padding: 0;
     margin: 0;
     display: flex;
     flex-direction: column;
     align-content: center;
     justify-content: center;
   }
   #menu li
    {
      padding-top: 1.75rem;
    }
   div .projects{
     left: 0;
     width: 60%;
     padding: 0;
     margin: 0 auto;
   }
   div .projects .project{
     width: 100%;
     overflow: hidden;
   }
  #menu li {
    text-align: center;
    width: 100%;
  }
  #icewrap-secound{
    width: 12rem !important;
    left:0  !important;
    margin:0 0.8rem;
   }
   #icewrap-first{
     width: 5rem !important;
     right: 0 !important;
   }
   .DennikN{
    img:nth-child(1){
      width: 10rem !important;
      top: 0 !important;
      right: -1rem;
    }
     img:nth-child(2){
       top: -1.5rem !important;
       left: -5px !important;
     }
   }
   .surglogs{
    img{
      position: relative;
      z-index: 1;
      top: 0rem !important;
      right: 0rem !important;
      margin: 0;
    }
  }
 }
@media (min-width: 1450px){
  #menuToggle input:checked ~ div
        {
          transform: scale(6, 1.0);
        }
}
