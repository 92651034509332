
$textSize46: 2.875rem; /*46px/16*/
$textSize20: 1.25em; /*20px/16*/
$textSize12: 0.75rem; /*12px/16*/
$imageWrapperWidth: 30.348795397339086659475008989572%;

.content-wrapper{
    width: 61.811111111111111111111111111111%; 
    margin-left:23.266666666666666666666666666667%;
    position: relative;
    .right-text-holder{
        width: 64.059690758719884933477166486875%;
        margin-left: 37.648327939590075512405609492988%;
        small{
            display: block;
            font-size: $textSize12;
            color: #B4B8BF;
            letter-spacing: 0;
            padding-top: 9.56rem;
            text-transform: uppercase;
        }
        h2{
            color: #000000;
            margin: 1.13rem 0;
        }
        p{
            opacity: 0.6;
            color: #000000;
            letter-spacing: 0;
            max-width: 100%;
            padding: 0.75rem 0; 
            margin:0;
        }
        a{
            color: #FA124F;
            letter-spacing: 0;
            display: inline-block;
            margin-top: 1.13rem;
            margin-bottom: 14.94rem;
        }
    }
    .IceWarp-img-holder{
        position: absolute;
        left: 0;
        top: 7.5rem;
        width: 18.13rem;
        height: 27.5rem;
        .IceWarp-img-wrapper{
            width: 100%;
            position: relative;
            height: 25rem;
            z-index: 2;
            left: 0;
            background: url(./../../assets/photo-1491911923017-19f90d8d7f83copy@2x.png) no-repeat center center;
            background-size: 100% auto;
            .IceWarpTextImage{
                width: 14rem;
                position: absolute;
                top: 7.13rem;
                left: 1rem;
            }
            .chat{
                position: absolute;
                left: 3.25rem;
                top: 20.81rem;
                width: 14.88rem;
            }
        }
        .IceWarp-dots{
            position: relative;
            background: url(./../../assets/mofa-pattern/Artboard_for_Songoroo.svg) repeat center center;
            z-index: 1;
            opacity: 0.5;
            background-position-y: 0px;
            display: block;
            height: 25.63rem;
            width: 100%;
            left: -1.88rem;
            top:-23.75rem;
        }
        .IceWarpLogo{
            position: relative;
            left: 1.88rem;
            top: 1.88rem;
        }
    }
}