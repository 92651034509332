@import './../../styles/variables.scss';

.songoroo-page{
    .dark-info-wrapper{
        margin-top: $space-big;
    }
    .home-page-wrapper{
        overflow: unset;
    }
    .songoroo-page-header-wrapper{
        width: 100%;
        background: url('./../../assets/hero@2x.png') no-repeat;
        background-position: center;
        background-size: cover;
        .songoroo-page-header{
            width: $content-width;
            margin: 0 auto;
            h1{
                color: $color-light;
                padding-top: 10.625rem;
                margin: 0;
                @media (max-width:1300px) {
                    padding-top: $space-big - 1 + 3.25;
                }

                @media (max-width:1160px) {
                    padding-top: $space-big - 1.5 + 3.25;
                }

                @media (max-width:1000px) {
                    padding-top: $space-big - 2.5 + 3.25;
                }

                @media (max-width:800px) {
                    padding-top: $space-big - 3 + 3.25;
                }

                @media (max-width:700px) {
                    padding-top: $space-big - 3.5 + 3.25;
                }
                @media (max-width:550px) {
                    padding-top: $space-big - 4.5 + 3.25;
                }
                @media (max-width:450px) {
                    font-size: 2.1rem;
                    line-height: 2.475rem;
                    padding-top: $space-big - 5 + 3.25;
                }
            }
            p{
                opacity: 0.6;
                color: $color-light;
                margin: 0;

                padding-top: $space-small;
                padding-bottom: $space-normal*5;
                @media (max-width:1160px) {
                    padding-bottom: $space-normal*4;
                }

                @media (max-width:1000px) {
                    padding-bottom: $space-normal*4;
                }

                @media (max-width:900px) {
                    padding-bottom: $space-normal*3;
                }

                @media (max-width:700px) {
                    padding-bottom: $space-normal*2.5;
                    padding-top: $space-small/2;
                }
            }
            @media (max-width:1000px) {
                width: $content-width-responsive;
            }
        }
    }

    .songoroo-page-post-header{
        background-color: $color-dark;
        width: $content-width;
        margin: 0 auto;
        margin-top: -$space-normal;
        p{
            max-width: 48.73%;
            color: $color-light;

            padding: $space-big;
            padding-left: $space-big - 1.25;

            @media (max-width:1160px) {
                padding: $space-big - 1;
                padding-left: $space-normal;
                max-width: 55%;
            }
            @media (max-width:900px) {
                display: block;
                padding-right: 0;
                padding-left: 0;
                max-width: 86%;
                padding-bottom: .5rem;
                margin: 0 auto;
                padding-top: $space-normal;
            }
            @media (max-width:700px) {
                padding: $space-normal 0;
                padding-bottom: 0;
                max-width: 80%;
                margin: 0 auto;
            }
            @media (max-width:600px) {
                padding: $space-small 0;
                padding-bottom: 0;
            }
            @media (max-width:450px) {
                font-size: 0.85rem;
                line-height: 1.275rem;
                text-align: justify;
            }
        }
        div{
            background: url("./../../assets/artboard-white.svg");
            height: 410px;
            width: 19%;
            max-width: 270px;
            display: block;
            position: absolute;
            left: 65%;
            top: 48%;
            opacity: .5;
            @media (max-width:1160px) {
                top: 39%;
            }
            @media (max-width:900px) {
                max-width: unset;
                position: unset;
                margin: 0 auto;
                height: 70px;
                width: 90%;
                max-width: 85%;
                margin: 0 auto;
                margin-top: 1rem;
            }
            
            @media (max-width:700px) {
                height: 60px;
            }
            @media (max-width:600px) {
                height: 40px;
            }
        }
        @media (max-width:1000px) {
            width: $content-width-responsive;
        }
        @media (max-width:900px) {
            padding-bottom: $space-small;
        }
        @media (max-width:800px) {
            border-radius: 10px;
        }
    }

    .songoroo-page-text-wrapper{
        width: $content-width;
        margin: 0 auto;
        padding-top: $space-big;
        padding-bottom: $space-big - 1.25;
        .songoroo-page-text{
            padding-left: $space-big + 5;
            max-width: 48.71%;
            h2{
                margin: 0;
                color: $color-dark;
            }
            p{
                color: $color-dark;
                opacity: 0.6;
                margin: 0;
                padding-top: $space-small;
                @media (max-width:450px) {
                    font-size: 0.85rem;
                    line-height: 1.275rem;
                    text-align: justify;
                }
            }
            .second-text{
                padding-top: $space-small/3;
            }
            .third-text{
                padding-top: $space-small/3;
            }
            @media (max-width:1300px) {
                padding-left: $space-big + 2;
                max-width: 60%;
            }
            @media (max-width:1160px) {
                padding-left: $space-big;
            }
            @media (max-width:1000px) {
                padding-left: $space-big - 2;
                max-width: 70%;
                width: $content-width;
            }
            @media (max-width:800px) {
                padding-left: $space-big - 4;
                max-width: 75%;
            }
            @media (max-width:700px) {
                padding-left: $space-normal - 1;
                max-width: 80%;
            }
            @media (max-width:600px) {
                padding-left: $space-normal - 2;
                max-width: 85%;
            }
            @media (max-width:550px) {
                max-width: 90%;
            }
            @media (max-width:500px) {
                max-width: 100%;
                margin: 0 auto;
                padding: 0;
            }
            @media (max-width:450px) {
                margin: 0 auto;
                width: 90%;
            }
        }
        @media (max-width:1000px) {
            width: $content-width-responsive;
        }
        @media (max-width:450px) {
            padding: $space-normal 0;
        }
    }

    .songoroo-fill-image{
        position: relative;
        width: 100%;
        background-color: #4f449d;
        display: flex;
        align-items: center;
        img{
            position: relative;
            z-index: 400;
            left: 20%;
            padding: 7.8% 0;
            width: 20%;
            @media (max-width:1300px) {
                width: 30%;
                left: 15%;
            }
        }
        canvas{
            height: 100%;
            z-index: 300;
            width: 100%;
        }
    }

    .songoroo-page-img-fullsize{
        max-width: 100%;    
        img{
            max-width: 100%;
        }
    }

    .songoroo-page-img{
        width: 67.63%;
        margin: 0 auto;
        img{
            max-width: 100%;
        }
        @media (max-width:1300px) {
            width: 75%;
        }
        @media (max-width:1160px) {
            width: 80%;
        }
        @media (max-width:1000px) {
            width: 85%;
        }
        @media (max-width:800px) {
            width: 90%;
        }
        @media (max-width:450px) {
            width: 100%;
        }
    }

    .songoroo-page-post-content{
        display: flex;
        background-color: $color-dark;
        margin: 0 auto;
        flex-wrap: wrap;
        .songoroo-page-post-content-flex{
            width: 76.736111111111111%;
            margin: 0 auto;
            display: flex;
            .post-content-text-wrapper{
                max-width: 51.58%;
                p{
                    margin: 0;
                    padding: $space-big 0;
                    color: $color-light;
                    opacity: .6;
                    @media (max-width:900px) {
                        padding-bottom: $space-small;
                        padding-top: $space-normal;
                        padding-left: 0;
                    }
                }
                @media (max-width:900px) {
                    max-width:86%;
                    padding-left: 0;
                    margin: 0 auto;
                    text-align: justify;
                }
                @media (max-width:450px) {
                    margin: 0 auto;
                    max-width: 90%;
                    padding: 0;
                }
            }
            .white-dot-pattern{
                background: url("./../../assets/artboard-purple.svg");
                height: 100%;
                width: 25%;
                max-width: 270px;
                display: block;
                opacity: .5;
                align-self: flex-end;
                margin-left: $space-big + .5;

                @media (max-width:1300px) {
                }
                @media (max-width:1160px) {
                    width: 217px;
                }
                @media (max-width:900px) {
                    max-width: unset;
                    width: 90%;
                    margin: 0 auto;
                    height: 80px;
                }
                @media (max-width:700px) {
                    height: 80px;
                }
                @media (max-width:600px) {
                    height: 65px;
                }
            }
            @media (max-width:1300px) {
                width: 75%;
            }
            @media (max-width:1160px) {
                width: 80%;
            }
            @media (max-width:1000px) {
                width: 85%;
            }
            @media (max-width:900px) {
                width: 90%;
                flex-wrap: wrap;
            }
        }

    }

    .songoroo-team-members-wrapper{
        margin-top: -4rem;
        background-color: $color-dark;
        padding-bottom: $space-normal;
        .songoroo-team-members-content{
            width: 76.736111111111111%;
            margin: 0 auto;
            .project-developers {
                margin-bottom: 0;
                left: 0%;
                .developer{
                    img{
                        border: 6px solid $color-dark;
                    }
                }
                .developer:nth-child(4){
                    
                    @media (max-width:515px) {
                        right: 0!important;
                    }
                }
                .others-developers{
                    background-color: #1f1f1f;
                    border: 6px solid $color-dark;
                    h2{
                        color:$color-red;
                    }
                    p{
                        color:$color-red;
                    }
                }
                @media (max-width:1600px) {
                    left: 10%;
                }
                @media (max-width:1500px) {
                    left: 5%;
                }
                @media (max-width:1400px) {
                    left: 0;
                }
            }
            @media (max-width:1000px) {
                width:$content-width-responsive;
            }
        }
        @media (max-width:900px) {
            margin-top: 0;
            padding-top: $space-small;
        }
    }
    
}