@import "./../../styles/variables.scss";

.fade-out {
    -webkit-animation: fade-out 1s ease-out both;
    animation: fade-out 1s ease-out both;
}

.slide-in-elliptic-top-fwd {
    -webkit-animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-elliptic-top-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: .5s;
}

.slide-in-elliptic-bottom-bck {
    -webkit-animation: slide-in-elliptic-bottom-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-elliptic-bottom-bck 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: .5s;
}

.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.se-pre-con {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: url("./../../assets/802.gif") center no-repeat #fff;
}

.page-404{
    background-image: url("./../../assets/nasa-63032-unsplash@2x.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -240px;
    width: 100%;
    .page-404-content{
        width: $content-width;
        margin: 0 auto;
        h1{
            max-width:50%;
            padding-top: 20rem;
            color: $color-light;
            margin: 0;
            padding-bottom: $space-small;
            @media(max-width:1440px) {
                padding-top: 18rem;
                max-width: 60%;
            }
            @media(max-width:1250px) {
                padding-top: 15rem;
            }
            @media(max-width:1060px) {
                max-width: 70%;
            }
            @media(max-width:750px) {
                padding-top: 12rem;
                max-width: 80%;
            }
            @media(max-width:700px) {
                padding-top: 10rem;
            }
            @media(max-width:600px) {
                padding-top: 8rem;
            }
            @media(max-width:450px) {
                max-width: 90%;
            }
            @media(max-width:350px) {
                max-width: 100%;
            }
        }
        p{
            max-width:50%;
            color: $color-light;
            opacity: .6;
            margin: 0;
            padding-bottom: $space-small;
            @media(max-width:1440px) {
                max-width: 60%;
            }
            @media(max-width:1060px) {
                max-width: 70%;
            }
            @media(max-width:750px) {
                max-width: 80%;
            }
            @media(max-width:450px) {
                max-width: 90%;
            }
            @media(max-width:350px) {
                max-width: 100%;
            }
        }
        a{
            max-width:50%;
            color: $color-red;
            margin-top: 0;
            display: inline-block;
            @media(max-width:1440px) {
                max-width: 60%;
            }
            @media(max-width:1060px) {
                max-width: 70%;
            }
            @media(max-width:750px) {
                max-width: 80%;
            }
            @media(max-width:450px) {
                max-width: 90%;
            }
            @media(max-width:350px) {
                max-width: 100%;
            }
        }
        a:after {
            content: '';
            display: block;
            border-bottom: 2px solid #FA124F;
            width: 0;
            position: relative;
            transition: .3s cubic-bezier(.22, .68, 0, 1.1);
        }

        a:hover:after {
            width: 100%;
        }
        @media(max-width:1000px){
            width: $content-width-responsive;
        }
    }
    @media(max-width:1700px) {
        background-position-y: -100px;
    }
    @media(max-width:1440px) {
        background-image: url("./../../assets/nasa-63032-unsplash.png");
    }
    @media(max-width:1210px) {
        background-position-y: -40px;
    }
    @media(max-width:1070px) {
        background-position-y: 0;
    }
    @media(max-width:450px) {
        background-position-x: -100px;
    }
    @media(max-width:350px) {
        background-position-x: -150px;
    }
}
.space-bg{
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
    height: 23.5625rem;
    @media(max-width:1320px) {
        height: 20rem;
    }
    @media(max-width:1210px) {
        height: 17rem;
    }
    @media(max-width:350px) {
        height: 10rem;
    }
}

@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@-webkit-keyframes slide-in-elliptic-bottom-bck {
    0% {
        -webkit-transform: translateY(600px) rotateX(-30deg) scale(6.5);
        transform: translateY(600px) rotateX(-30deg) scale(6.5);
        -webkit-transform-origin: 50% -100%;
        transform-origin: 50% -100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 500px;
        transform-origin: 50% 500px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-bottom-bck {
    0% {
        -webkit-transform: translateY(600px) rotateX(-30deg) scale(6.5);
        transform: translateY(600px) rotateX(-30deg) scale(6.5);
        -webkit-transform-origin: 50% -100%;
        transform-origin: 50% -100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 500px;
        transform-origin: 50% 500px;
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-6-3 17:58:49
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
