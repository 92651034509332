@import "./../../styles/variables.scss";

.home-page-carousel-background{
    background-color: $color-dark;
    width: 100%;
    @media (max-width:1000px){
        overflow: hidden;
    }
}

.home-page-carousel-wrap{
    padding-top: $space-big;
    width: 62.5%;
    margin: 0 auto;
    position: relative;
    .carousel-inner{
        overflow: unset!important;
    }
    .home-page-carousel{
        display: flex;
        justify-content: space-between;
        position: relative;
        .carousel-img{
            width: 30%;
            img{
                max-width: 100%;
                max-width: 270px;
                @media (max-width:1000px){
                    max-width: 200px;
                }
                @media (max-width:450px){
                    max-width: 160px;
                }
                @media (max-width:350px){
                    max-width: 130px;
                }
            }
            @media (max-width:1000px){
                align-self: flex-end;
                margin-top: $space-small;
                margin-right: 10%;
            }
            @media (max-width:600px){
                margin-right: 20%;
            }
            @media (max-width:350px){
                margin-top: $space-normal;
            }
        }

        .carousel-texts{
            max-width: 63.333333333333333%;
            p{
                color: $color-light;
                @media (max-width:700px){
                    text-align: justify;
                }
                @media (max-width:350px){
                    margin: .5rem 0;
                    font-size: .6rem;
                }
            }
            p:nth-child(2){
                opacity: .6;
            }
            small{
                color:$color-light;
                opacity: .6;
                @media (max-width:350px){
                    font-size: .6rem;
                }
            }
            @media (max-width:1000px){
                max-width: 90%;
                margin: 0 auto;
                height: 12rem;
            }
            @media (max-width:450px){
                max-width: 95%;
            }
            @media (max-width:400px){
                height: 15rem;
            }
        }
        .carousel-img-after{
            background-image: url("./../../assets/Artboard.svg");
            width: 270px;
            height: auto;
            height: 400px;
            position: absolute;
            left: 72.7%;
            top: 10.5%;
            z-index: -5;
            .carousel-logo-small{
                height: 5rem;
                width: 5rem;
                background-image: url("./../../assets/logo-songoo.svg");
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                right: 100px;
                top: 69.5%;
                @media only screen and (max-width: 1920px) and (min-width: 1500px){
                    right: 105px;
                }
                @media only screen and (max-width: 1920px) and (min-width: 1700px){
                    right: 110px;
                }
                @media (max-width:1000px){
                    display: none;
                }
            }
            .carousel-logo-small2{
                height: 5rem;
                width: 5rem;
                background-image: url("./../../assets/logo-icewrap.svg");
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                right: 100px;
                top: 69.5%;
                @media only screen and (max-width: 1920px) and (min-width: 1500px){
                    right: 105px;
                }
                @media only screen and (max-width: 1920px) and (min-width: 1700px){
                    right: 110px;
                }
                @media (max-width:1000px){
                    display: none;
                }
            }
            @media (max-width:1000px){
                width: 200px;
                height: 300.296296px;
                top: 47.3%;
                left: 65%;
            }
            @media (max-width:600px){
                left: 55%;
            }
            @media (max-width:450px){
                width: 160px;
                height: 245px;
                left: 57%;
                top: 53.3%;
            }
            @media (max-width:400px){
                top: 56.3%;
            }
            @media (max-width:350px){
                width: 130px;
                height: 215px;
                left: 57%;
                top: 53.3%;
            }
            @media (max-width:350px){
                width: 130px;
                height: 202px;
                left: 57%;
                top: 60.3%;
            }
        }
        @media (max-width:1000px){
            flex-direction: column;
        }
    }

    .carousel-control-prev{
        height: 5rem;
        width: 5rem;
        background:rgba(167, 179, 196,.2);
        background-image: url("./../../assets/arrow-left.svg");
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        margin-right: 1px;
        position: relative;
        top: -83px;
        cursor: pointer;
        transition: .3s;
        div{
            display: none;
        }
        @media (max-width:1000px){
            height: 4rem;
            width: 4rem;
            top: -67px;
            left: 5%;
        }
        @media (max-width:450px){
            height: 3.5rem;
            width: 3.5rem;
        }
        @media (max-width:350px){
            height: 3rem;
            width: 3rem;
            top: -52px;
        }
    }
    .carousel-control-next{
        height: 5rem;
        width: 5rem;
        background: rgba(167, 179, 196,.2);
        background-image: url("./../../assets/arrow-left-copy.svg");
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        top: -83px;
        display: inline-block;
        transition: .3s;
        div{
            display: none;
        }
        @media (max-width:1000px){
            height: 4rem;
            width: 4rem;
            top: -67px;
            left: 5%;
        }
        @media (max-width:450px){
            height: 3.5rem;
            width: 3.5rem;
        }
        @media (max-width:350px){
            height: 3rem;
            width: 3rem;
            top: -52px;
        }
    }

    .carousel-indicators{
        list-style-type: none;
        display: flex;
        padding-left: 0;
        position: absolute;
        top: 55%;
        z-index: 500;
        li{
            width: 6px;
            height: 6px;
            opacity: 0.4;
            background: #A7B3C4;
            border-radius: 3px;
            border-radius: 3px;
            margin: .1rem;
            cursor: pointer;
        }
        li:nth-child(1){
            margin-left: 0;
        }
        .active{
            opacity: 1;
            background-image: url("./../../assets/Rectangle.svg");
            cursor: pointer;
            width: 20px;
            height: 6px;
        }
        @media (max-width:1000px){
            left: 2.2rem;
            top: 38%;
        }
        @media (max-width:700px){
            left: 1.7rem;
        }
        @media (max-width:600px){
            left: 1.5rem;
        }
        @media (max-width:450px){
            top: 39%;
            left: 0.7rem;
        }
        @media (max-width:450px){
            top: 42%;
        }
        @media (max-width:350px){
            top: 48%;
        }
    }

    @media (max-width:1160px){
        width: 75%;
    }
    @media (max-width:1000px){
        width: 90%;
        padding: $space-normal 0;
    }
    @media (max-width:350px){
        padding: $space-small 0;
    }
}