
$textSize46: 2.875rem; /*46px/16*/
$textSize20: 1.25em; /*20px/16*/
$textSize12: 0.75rem; /*12px/16*/
$imageWrapperWidth: 30.348795397339086659475008989572%;

.content-wrapper{
    width: 61.811111111111111111111111111111%; 
    margin-left:23.266666666666666666666666666667%;
    position: relative;
    .right-text-holder{
        h2,p,a{
            width: unset;
        }
        a{
            color: #FA124F;
            display: inline-block;
            margin-top: 1.13rem;
            position: relative;
        }
        a:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: -2px;
            left: 0;
            background-color: #FA124F ;
            visibility: hidden;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: .3s cubic-bezier(.22, .68, 0, 1.1);
          }
          a:hover:before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
    }
    .songoroo-img-holder{
        position: absolute;
        left: 0;
        top: 7.5rem;
        width: 16.88rem;
        height: 25rem;
        .songoroo-img-wrapper{
            width: 100%;
            position: relative;
            height: 100%;
            z-index: 2;
            left: 0;
            background: #8C71C0;
            background-image: none;
            overflow: hidden;
            .lines{
                position: relative;
                left: -19.5rem;
            }
        }
        .songoroo-dots{
            position: relative;
            background: url(./../../assets/mofa-pattern/Artboard_for_Songoroo.svg) repeat center center;
            z-index: 1;
            opacity: 0.5;
            background-position-y: 0px;
            display: block;
            height: 100%;
            width: 100%;
            left: -1.88rem;
            top:-23.75rem;
        }
        .SongAppLogo{
            position: relative;
            left: 6%;
            top: 0.88rem;
        }
        
        .song-images-wrapper{
            position: absolute;
            top:8.69rem;
            display: flex;
            left: -7%;
            width:20rem;
            height: 20rem;
            align-content: stretch;
            flex-wrap: wrap;
            z-index: 5;
            div{
                img{
                    margin: 0;
                    padding: 0;
                }
            }
            .songimg-wrapper1{
                height: 7.5rem;
                width: 100%;
                .mid-width{
                    width: 31.25%;
                    height: auto;
                }
                .big-width{
                    max-width: 37.5%;
                    position: relative;
                    top: 0;
                }
            }
            .songimg-wrapper2{
                display: flex;
                flex-wrap: wrap;
                height: 12.5rem;;
                width: 100%;
                position: relative;
                left: 4.4%;
                .mid-width{
                    width: 31.25%;
                    height: 50%;
                }
                .small-width{
                    width: 25%;
                    height: 40%;
                }
                .mid-width2{
                    width: 34.375%;
                    height: 55%;
                }
                img:nth-child(4){
                    position: relative;
                    top:-1.9rem;
                }
                img:nth-child(5){
                    position: relative;
                    top:-0.7rem;
                }
            }
        }
    }
}
