@import "./../../styles/variables.scss";
@import "./../../styles/animations.scss";
$textSize12: 0.75rem; /*12px/16*/
$textWidth: 48.717948717948717948717948717949%;
$imageWrapperWidth: 30.348795397339086659475008989572%;
$imageHeight: 25rem;

// .layout{
//     transition: .5s;
//     background-color: #fff;
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     height: 100vh;
//     z-index: 500;
// }
.hp-icon{
    img{
        max-width:71.428571428571428571428571428571%;
    }
}
.home-page-wrapper{
    overflow: hidden;
    .content-wrapper-first{
        position: relative;
        z-index: 501;
        width: 81.25%;
        margin: 0 auto;    
        h1{
            color: #000000;
            margin: 0;
            padding:7.5rem 0 2rem 0;
            max-width: 65.811965811965811965811965811966%;
        }
        p{
            max-width: $textWidth;
            padding: 1.875rem 0;
            opacity: 0.6;
            color: #2F2F2F;
            margin: 0;
        }
        small{
            font-size: 0.875rem;
            color: #B4B8BF;
            letter-spacing: 0;
            padding: 1.875rem 0;
        }
        ul{
            margin: 0;
            margin-top: 1.875rem;
            padding: 0;
            width:47.863247863247863247863247863248%;
            list-style: none;
            display: flex;
            li{
                display: flex;
                max-width: 17.857142857142857142857142857143%;
                width: 6.25rem;
                height: 6.25rem;
                margin-bottom: 7.5rem;
                align-items: center;
                justify-content: center;
                img{
                    max-width: 100%;
                }
            }
        }
    }
    section{
        .content-wrapper{
            position: relative;
            z-index: 501;
            width: 61.811111111111111111111111111111%; 
            margin-left:23.266666666666666666666666666667%;
            position: relative;
            .right-text-holder{
                margin-left: 20.63rem;
            }
            .text-holder{
                width: 64.059690758719884933477166486875%;
                small{
                    display: block;
                    font-size: $textSize12;
                    color: #B4B8BF;
                    letter-spacing: 0;
                    padding-top: 9.56rem;
                    text-transform: uppercase;
                }
                h2{
                    color: #FFFFFF;
                    margin: 1.13rem 0;
                }
                p{
                    opacity: 0.6;
                    color: #FFFFFF;
                    max-width: 100%;
                    padding: 0.75rem 0; 
                    margin:0;
                }
                a{
                    color: #FA124F;
                    display: inline-block;
                    margin-top: 1.13rem;
                    position: relative;
                }
                a:before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: -2px;
                    left: 0;
                    background-color: #FA124F ;
                    visibility: hidden;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    transform-origin: 0 100%;
                    transition: .3s cubic-bezier(.22,.68,0,1.1);
                  }
                  a:hover:before {
                    visibility: visible;
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                  }
                .small1{
                    padding-top: 20.56rem;
                }
                .small2 {
                    padding-top: 2.56rem;
                }
                .surg-mar-bot{
                    margin-bottom:9.94rem;
                }
                .mar-bot{
                    margin-bottom: 18.69rem;
                }
            }
            .clients-holder{
                margin: 0;
                padding: 0;
                display: flex;
                list-style: none;
                flex-wrap: wrap;
                max-width: 54.68rem;
                padding-top: 1.13rem;
                z-index: 1;
                li{
                    width: 8.75rem;
                    height: 8.75rem;
                    margin: 0.155rem;
                    background: rgba(33, 36, 39,0.46);
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        position: relative;
                        z-index: 2;
                    }
                }
                li:last-child{
                    margin-bottom: 7.5rem;
                }
                .invisible-clients{
                    opacity: 0;
                }
            }
            .client-dots{
                position: absolute;
                background: url(./../../assets/mofa-pattern/Artboard.svg) repeat center center;
                z-index: 1;
                display: block;
                background-position-y: 0px;
                height: 25.63rem;
                width: 30.343%;
                max-width: 17rem;
                opacity: 0.5;
                left: -12.5rem;
                top: 19rem;
            }
            .img-holder{
                position: absolute;
                left: 67.439769866954332973750449478605%;
                top: 7.5rem;
                width: 16.88rem;
                height: 25rem;
                .dennikN-photo{
                    background: #BF2134!important;
                    background-image: none!important;
                    position: absolute;
                    top: -25px;
                    left: -25px;
                    img:nth-child(2){
                        max-width: 509px;
                        max-height: 629px;
                        @media(max-width: 600px){
                            max-width: 409px;
                        }
                    }
                }
                .appLogo{
                    position: relative;
                    left: 10%;
                    top:1.88rem;
                }
                .img-wrapper{
                    width: 100%;
                    position: relative;
                    height: 100%;
                    background: url(./../../assets/team-1.jpg) no-repeat center center;
                    background-size: auto 100%;
                    z-index: 2;
                    
                }
                .dots-dennikn {
                    position: relative;
                    background: url(./../../assets/mofa-pattern/Artboard.svg) repeat center center ;
                    z-index: 1;
                    display: block;
                    background-position-y: 0px;
                    height: 100%;
                    width: 100%;
                    opacity: 0.5;
                    left: 1.88rem;
                    top: -22.75rem;
                }
                .dots{
                    position: relative;
                    background: url(./../../assets/mofa-pattern/Artboard.svg) repeat center center;
                    z-index: 1;
                    display: block;
                    background-position-y: 0px;
                    height: 100%;
                    width: 100%;
                    opacity: 0.5;
                    left: 1.88rem;
                    top:-22.75rem;
                }
                .surgLogsDots{
                    background: url(./../../assets/mofa-pattern/Artboard_for_Surglogs.svg) repeat center center;
                }
                .iphone-photo{
                    background: url(../../assets/hp-cs-surglogs.png) no-repeat center center;
                    position: absolute;
                    width: 44.32rem;
                    height: 43.7rem;
                    left: -55%;
                    top: -1.9rem;
                    z-index: 3;
                    @media (max-width:600px){
                        max-width: 409px;
                        background-size: contain;
                        top: -7.7rem!important;
                        left: 0!important;
                    }
                    @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                        background: url(./../../assets/hp-cs-surglogs@2x.png) no-repeat center center;
                        max-width: 509px;
                        max-height: 629px;
                        background-size: contain
                    }
                    @media all and (-webkit-min-device-pixel-ratio: 1.5) and (max-width:600px) {
                        max-width: 409px;
                    }
                }
                .surg-logs-photo{
                    background: #51B5F3;
                }
            }
            .services-holder{
                top: 42.5rem;
                .startup-photo1{
                    background: url(./../../assets/startup-1.jpg) no-repeat center center;
                    background-size: auto 25rem;
                }
            }
            .flat-holder{
                top: 77.5rem;
                .startup-photo2{
                    background: url(./../../assets/photo-flat.png) no-repeat center center;
                    background-size: auto 25rem;
                    @media all and (-webkit-min-device-pixel-ratio: 1.5) {
                        background: url(./../../assets/photo-flat@2x.png) no-repeat center center;
                    }
                }
            }
            .text-holder:nth-child(3){
                margin-left: 28.648328%;
                @media (max-width:1800px){
                    margin-left: 30.648328%;
                }
                @media (max-width:1700px) {
                    margin-left: 32.648328%;
                }
                @media (max-width:1600px) {
                    margin-left: 34.648328%;
                }
                @media (max-width:1500px) {
                    margin-left: 36.648328%;
                }
                @media (max-width:1400px) {
                    margin-left: 37.648328%;
                }
            }
            .img-holder:nth-child(4){
                left: 0;
                .img-wrapper{
                    left: 0;
                }
                .dots{
                    left: -1.88rem;
                }
            }
            
        }
    }
   
    section:nth-child(even){
        background-color: #000000;
    }
    section:nth-child(odd){
        .content-wrapper{
            .text-holder{
                h2,p{
                    color: #000000;
                }
            }
        }
    }
}





/*MEDIA QUERIES*/

@media (max-width:1350px){
    .home-page-wrapper{
        section{
            .content-wrapper{
                width: 70%;  
                margin-left: 20%;
            }
        }
    }
} 

@media (max-width:1300px) {
    .home-page-wrapper{
        .content-wrapper-first{
            h1{
                max-width: 85%;
                padding: 6 + 3.25rem 0 2rem 0;
            }
            p{
                padding: 1.7rem 0;
                max-width: 50%;
            }
            small{
                font-size: 0.8rem;
                padding: 1.7rem 0;
            }
            ul{
                width: 50%;
                li{
                    margin-bottom: 7rem;
                }
            }
        }
            section{
                .content-wrapper{  
                    margin: 0 auto;
                    .text-holder:nth-child(3),
                    .right-text-holder{
                        margin-left: 22rem;
                    }
                }
            }
    }
  }
  @media (max-width:1100px) {
    .home-page-wrapper{
        .content-wrapper-first{
            h1{
                padding:5 + 3.25rem 0 1.5rem 0;
            }
            p{
                padding: 1.6rem 0;
                max-width: 60%;
            }
            small{
            font-size: 0.7rem;
            padding: 1.6rem 0;
            }
            ul{
                width: 60%;
                li{
                    margin-bottom: 6rem;
                }
            }
        }
        section{
            .content-wrapper{
                display: flex;
                flex-wrap: wrap;
                .img-holder{
                    margin: 6rem auto;
                    position: unset;
                    order: 2;
                    width: 16.88rem;
                    .img-wrapper{
                        width: 100%;
                        .iphone-photo{
                            top: -2.7rem;
                            left: -54%;
                        }
                    }
                    .dots{
                        width: 100%;
                        max-width: 17rem;
                    }
                }
                .IceWarp-img-holder{
                    margin: 6rem auto;
                    position: relative;
                    order: 2;
                    width: 18.13rem;
                    min-width: 16rem;
                    top: 0;
                }
                .songoroo-img-holder{
                    margin: 6rem auto;
                    position: relative;
                    order: 2;
                    width: 16.88rem;
                    min-width: 16rem;
                    top: 0;
                }
                .team-holder {
                    width: 50%;
                    .dots{
                        max-width: unset;
                    }
                }
                .flat-holder {
                    width: 50%;

                    .dots {
                        max-width: unset;
                    }
                }
                .text-holder,
                .right-text-holder{
                    order: 1;
                    width: 100%;
                    margin:0;
                    small{
                        padding-top: 6rem;
                    }
                    .small1{
                        padding-top: 0;
                    }
                    .mar-bot,
                    .surg-mar-bot{
                        margin-bottom: 0;
                    }
                    a{
                        margin-bottom: 0;
                    }
                }
                .tech-text{
                    order: 0;
                }
                .text-holder:nth-child(3){
                    margin-left: 0;
                    order: 3;
                }

                .text-holder:nth-child(5) {
                    margin-left: 0;
                    order: 5;
                }
                .img-holder:nth-child(4){
                    order: 4;
                }
                .img-holder:nth-child(6) {
                    order: 6;
                }

            }
        }
    }
  }



@media (max-width:800px) {
    .home-page-wrapper{
        .content-wrapper-first{
            max-width: 90%;
            h1{
                padding: 4 + 3.25rem 0 1.2rem 0;
            }
            p{
                padding: 1.5rem 0;
                max-width: 70%;
            }
            small{
                font-size: 0.65rem;
                padding: 1.55rem 0;
            }
            ul{
                width: 70%;
                li{
                    margin-bottom: 5rem;
                }
            }
        }
        section{
            .content-wrapper{
                width: 80%;  
                .frameworks-holder{
                    li{
                        width: 8rem;
                        height: 8rem;
                        img{
                            max-width: 100%;
                        }
                    }
                }
                .not-dots{
                    display: none;
                }
            }
        }
    }

  }


  @media (max-width:700px) {
    .home-page-wrapper{
        .content-wrapper-first{
            h1{
                max-width: 100%;
            }
            p{
                padding: 1.4rem 0;
                max-width: 75%;
            }
            small{
                font-size: 0.6rem;
                padding: 1.5rem 0;
            }
            ul{
                width: 75%;
                li{
                    margin-bottom: 4rem;
                }
            }
        }
        section{
            .content-wrapper{
                display: flex;
                flex-wrap: wrap;
                .img-holder{
                    margin: 6rem auto;
                    position: unset;
                    order: 2;
                    width: 50%;
                    min-width: 16rem;
                    .img-wrapper{
                        width: 100%;
                    }
                    .dots{
                        width: 100%;
                        max-width: unset;
                    }
                }
                .songoroo-img-holder{
                    margin: 6rem auto;
                    order: 2;
                    width: 50%;
                    min-width: 16rem;
                }
                .services-holder .dots{
                    max-width: 17rem;
                }
                .text-holder{
                    order: 1;
                    width: 100%;
                    small{
                        padding-top: 6rem;
                    }
                    .small1{
                        padding-top: 0;
                    }
                    .mar-bot,
                    .surg-mar-bot{
                        margin-bottom: 0;
                    }
                }
                .tech-text{
                    order: 0;
                }
                .text-holder:nth-child(3){
                    margin-left: 0;
                    order: 3;
                }
                .text-holder:nth-child(5) {
                   margin-left: 0;
                   order: 5;
                }
                .img-holder:nth-child(4){
                    order: 4;
                }
                .img-holder:nth-child(6) {
                    order: 6;
                }
                .frameworks-holder{
                    li{
                        width: 7.5rem;
                        height: 7.5rem;
                        img{
                            max-width: 100%;
                        }
                    }
                }

            }
        }
    }
  }


@media (max-width:600px){
    .home-page-wrapper{
        section{
            .content-wrapper{
                .img-holder,
                .songoroo-img-holder{
                    margin: 4rem auto;
                    height: 20rem;
                    .dots,
                    .songoroo-dots{
                        top: -17.75rem;
                    }
                    .img-wrapper{
                        .dennikN-iphone-photo{
                            position: relative;
                            top: -1.1rem;
                        }
                    }
                    .songoroo-img-wrapper{
                        .lines{
                            width: 33rem;
                            left: -14.5rem;
                        }
                    }
                    .song-images-wrapper{
                        top: 7.99rem;
                        left: -3%;
                        width: 17rem;
                        height: 17rem;
                        .songimg-wrapper1{
                            height: 6.4rem;
                        }
                        .songimg-wrapper2{
                            height: 10.625rem;
                        }
                    }
                }
                .songoroo-img-holder{
                    margin-bottom: 8rem;
                }
                .text-holder{
                    small{
                        padding-top: 4rem;
                    }
                }
                .frameworks-holder{
                    li{
                        width: 7rem;
                        height: 7rem;
                        img{
                            max-width: 100%;
                        }
                    }
                }
    
            }
        }
    }
}
@media (max-width: 500px){
    .home-page-wrapper{
        .content-wrapper-first{
            ul{
                width: 100%;
            }
        }  
    } 

}
@media (max-width: 370px){
    .home-page-wrapper{
        section{
            .content-wrapper{
                .img-holder{
                    .img-wrapper{
                        .iphone-photo{
                            left: -67.8%;
                        }
                        .dennikN-iphone-photo{
                            left: -0.7rem;
                        }
                    }
                }
                .IceWarp-img-holder{
                    .IceWarp-img-wrapper{
                        .chat{
                            width:82.068%;
                            top: 20.51rem;
                        }
                        .IceWarpTextImage{
                            width: 77.225%;
                        }
                    } 
                    .IceWarp-logo{
                        width: 21.375%;
                    }
                    .IceWarp-dots{
                        top: -21.75rem;
                        height: 23.63rem;
                    }
                } 
                .frameworks-holder{
                    li{
                        width: 6.6rem;
                        height: 6.6rem;
                    }
                }
            }
        }
    }
}