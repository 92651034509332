@import './../../styles/variables.scss';

.about-page{
    .about-page-post-header-wrap{
        background-color: $color-dark;
        .about-page-post-header{
            width: 81.25%;
            margin: 0 auto;
            h1 {
                color: $color-light;
                max-width: 65%;
  
                padding-top: 10.625rem;
                padding-bottom: $space-small;
                margin: 0;
                @media (max-width:1400px){
                    max-width: 68%;
                }
                @media (max-width:1300px){
                    max-width: 73%;
                    padding-top: $space-big - 1 + 3.25;
                }
                @media (max-width:1160px){
                    padding-top: $space-big - 1.5 + 3.25;
                    max-width: 76%;
                    padding-bottom: 1.5625rem;
                }
                @media (max-width:1000px){
                    padding-top: $space-big - 2.5 + 3.25;
                    max-width: 88%;
                }
                @media (max-width:900px){
                    max-width: 90%;
                    padding-top: 5rem;
                    padding-bottom: 1.25rem;
                }
                @media (max-width:800px){
                    max-width: 97%;
                    padding-top: $space-big - 3 + 3.25;
                    padding-bottom: 0.9375rem;
                }
                @media (max-width:700px){
                    max-width: 100%;
                    padding-top: $space-big - 3.5 + 3.25;
                    padding-bottom: 0.625rem;
                }
                @media (max-width:550px) {
                    padding-top: $space-big - 4.5 + 3.25;  
                }
                @media (max-width:450px) {
                    padding-top: $space-big - 5 + 3.25;
                }
                @media (max-width:350px){

                    font-size: 1.9rem;
                    line-height: 2.375rem;
                }
            }

            p{  
                max-width: 48.7179487179487%;
                opacity: .6;
                color: $color-light;

                margin: 0;
                padding: $space-small 0 $space-big 0;
                @media (max-width:1160px){
                    max-width: 65%;
                    padding-bottom: 6.25rem;
                    padding-top: 1.5625rem;
                }
                @media (max-width:900px){
                    max-width: 58%;
                    padding-bottom: 5rem;
                    padding-top: 1.25rem;
                }
                @media (max-width:800px){
                    max-width: 80%;
                    padding-top: 0.9375rem;
                    padding-bottom: $space-normal;
                }
                @media (max-width:700px){
                    padding-top: 0.625rem;
                }
                @media (max-width:400px){
                    font-size: .95rem;
                    line-height: 1.275rem;
                }
                @media (max-width:350px){
                    font-size: .85rem;
                    line-height: 1.175rem;
                    max-width: 90%;
                    margin: 0 auto;
                }
            }
            @media (max-width:1000px) {
                width:90%;
            }
        }
    }



    .about-page-video{
        max-width: $content-width;
        display: flex;
        justify-content: center;
        align-items: center;

        padding-bottom: $space-normal;
        margin: 0 auto;
        margin-top: -$space-normal;
        video{
            width: 100%;
            outline: none;
            border: none;
        }

        .playpause {
            position: absolute;
            cursor: pointer;
            width: 170px;
            height: 170px;
            img{
                max-width: 100%;
            }
            @media (max-width:1160px){
                width: 140px;
                height: 140px;
            }
            @media (max-width:1000px){
                width: 110px;
                height: 110px;
            }
            @media (max-width:700px){
                width: 90px;
                height: 90px;
            }
            @media (max-width:500px){
                width: 80px;
                height: 80px;
            }

        }

        @media only screen and (max-width: 1920px) and (min-width: 1200px){
            max-width: 971px;
        }
        @media (max-width:1160px){
            margin-top: -3.25rem;
        }
        @media (max-width:900px){
            margin-top: -2.5rem;
        }
        @media (max-width:800px){
            margin-top: -$space-small;
        }
        @media (max-width:700px){
            margin-top: -1.25rem;
            padding-bottom: 1.25rem;
        }
        @media (max-width:1000px){
            width: $content-width-responsive;
        }

        
    }




    .about-page-content{
        padding-top: $space-normal;
        width: 81.25%;
        margin: 0 auto;
        .about-page-content-photos{
            padding-bottom: $space-normal;
            display: flex;
            img{
                @media (max-width:1440px){
                    height: 100%;
                }
            }
            img:first-child{
                @media (max-width:1440px){
                    max-width: 570px;
                    max-height: 530px;
                    width: 48.717948717948718%;
                }
            }
            img:nth-child(2){
                position: relative;
                left: 2.5%;
                top: 6.25rem;
                z-index: 10;
                max-width: 370px;
                max-height: 370px;
                width: 31.623931623931624%;
                @media (max-width:1160px){
                    top: 4.25rem;
                }
                @media (max-width:810px){
                    top: 3.25rem;
                }
                @media (max-width:600px){
                    top: 2.5rem;
                }
            }
            img:nth-child(3){
                position: relative;
                left: -14%;
                top: -8.25rem;
                z-index: 9;
                max-width: 368px;
                max-height: 530px;
                width: 31.452991452991453%;
                @media (max-width:1000px){
                    top: -6.05rem;
                }
                @media (max-width:600px){
                    top: -5.15rem;
                }
            }
            @media only screen and (max-width: 1600px) and (min-width: 1500px){
                width: 90%;
                margin: 0 auto;
            }
            @media only screen and (max-width: 1700px) and (min-width: 1600px){
                width: 85%;
                margin: 0 auto;
            }
            @media only screen and (max-width: 1800px) and (min-width: 1700px){
                width: 80%;
                margin: 0 auto;
            }
            @media only screen and (max-width: 1920px) and (min-width: 1800px){
                width: 75%;
                margin: 0 auto;
            }
            @media (max-width:1440px){
                width: 100%;
                height: 100%;
            }
            @media (max-width: 1000px){
                padding-bottom: 1.5rem;
            }
        }
        p{
            max-width: 48.717948717948718%;
            opacity: .6;
            color: $color-dark;

            margin: 0;
            margin-left: 7rem;
            @media only screen and (max-width: 1600px) and (min-width: 1500px){
                margin-left: 13.25rem;
                max-width: 45.717949%;
            }
            @media only screen and (max-width: 1700px) and (min-width: 1600px){
                max-width: 40.717949%;
                margin-left: 17.9375rem;
            }
            @media only screen and (max-width: 1800px) and (min-width: 1700px){
                max-width: 36.717949%;
                margin-left: 18.9375rem;
            }
            @media only screen and (max-width: 1920px) and (min-width: 1800px){
                max-width: 36.717949%;
                margin-left: 20.9375rem;
            }
            @media (max-width:1160px){
                max-width: 54%;
                font-size: 1.15rem;
                line-height: 1.425rem;
                margin-left: 4rem;
            }
            @media (max-width:1000px){
                font-size: 1.05rem;
                line-height: 1.225rem;
                max-width: 57%;
                margin-left: 3rem;
            }
            @media (max-width:810px){
                font-size: .95rem;
                line-height: 1.125rem;
                max-width: 55%;
                margin-left: 2rem;
            }
            @media (max-width:650px){
                max-width: 58%;
                margin-left: 1rem;
                font-size: .85rem;
                line-height: 1.025rem;
            }
            @media (max-width:600px){
                max-width: 60%;
                margin-left: 1rem;
                font-size: .75rem;
                line-height: .925rem;
            }
            @media (max-width:400px){
                font-size: .65rem;
                line-height: .825rem;
            }
        }
        p:first-child{
            padding-bottom: $space-normal;
            @media (max-width: 1000px){
                padding-bottom: 1.5rem;
            }
        }
        .about-page-text2{
            margin-bottom: .5rem;
            @media (max-width:1000px){
                max-width:70% ;
            }
            @media (max-width:700px){
                max-width: 90%;
            }
        }
        .about-page-text3{
            padding-bottom: $space-big;
            @media (max-width:1160px){
                padding-bottom: 6.25rem;
            }
            @media (max-width:1000px){
                max-width:70% ;
            }
            @media (max-width:900px){
                padding-bottom: 5rem;
            }
            @media (max-width:800px){
                padding-bottom: $space-normal;
            }
            @media (max-width:700px){
                padding-bottom: 2.5rem;
                max-width: 90%;
            }
        }
        @media (max-width:1000px) {
            width:90%;
        }
        @media (max-width:700px){
            padding-top: 1.25rem;
        }
    }




    .about-page-post-content-wrap {
        background-color: $color-dark;
        margin-top: 3rem;
        .about-page-post-content {
            width: $content-width;

            padding-top: $space-normal;            
            margin: 0 auto;
            .about-page-post-content-photos {
                width: 100%;
                height: 100%;
                display: flex;
                img {
                    @media (max-width:1440px) {
                        height: 100%;
                    }
                }

                img:first-child {
                    max-width: 370px;
                    max-height: 500px;
                    width: 31.623931623931624%;
                    @media (max-width:450px) {
                        width: 48%;
                        margin-right: .5rem;
                    }
                }

                img:nth-child(2) {
                    position: relative;
                    left: -4.375rem;
                    top: 6.25rem;
                    z-index: 500;
                    max-width: 470px;
                    max-height: 320px;
                    width: 40.170940170940171%;

                    @media (max-width:1000px) {
                        top: 4.25rem;
                    }

                    @media (max-width:700px) {
                        top: 3.25rem;
                        left: -3.375rem;
                    }

                    @media (max-width:600px) {
                        top: 2.25rem;
                    }
                    @media (max-width:450px) {
                        position: unset;
                        order: 3;
                        width: 80%;
                        margin: 0 auto;
                        margin-top: -3rem;
                        
                    }
                }

                img:nth-child(3) {
                    position: relative;
                    left: -2.5rem;
                    top: -7.05rem;
                    z-index: 499;
                    max-width: 370px;
                    max-height: 600px;
                    width: 31.623931623931624%;
                    @media (max-width:450px) {
                        position: unset;
                        width: 48%;
                        max-height: 210px;
                    }
                }

                @media only screen and (max-width: 1600px) and (min-width: 1500px) {
                    width: 90%;
                    margin: 0 auto;
                }

                @media only screen and (max-width: 1700px) and (min-width: 1600px) {
                    width: 85%;
                    margin: 0 auto;
                }

                @media only screen and (max-width: 1800px) and (min-width: 1700px) {
                    width: 80%;
                    margin: 0 auto;
                }

                @media only screen and (max-width: 1920px) and (min-width: 1800px) {
                    width: 75%;
                    margin: 0 auto;
                }
                @media (max-width:450px) {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            p {
                color: $color-light;
                max-width: 48.717948717948718%;
                opacity: .6;

                margin: 0;
                margin-left: 6.25rem;
                padding-bottom: $space-normal;

                @media only screen and (max-width: 1600px) and (min-width: 1500px) {
                    margin-left: 10.25rem;
                }

                @media only screen and (max-width: 1700px) and (min-width: 1600px) {
                    margin-left: 11.25rem;
                }

                @media only screen and (max-width: 1800px) and (min-width: 1700px) {
                    margin-left: 14.25rem;
                }

                @media only screen and (max-width: 1920px) and (min-width: 1800px) {
                    margin-left: 15.25rem;
                }

                @media (max-width:1160px) {
                    max-width: 60%;

                    font-size: 1.4rem;
                    line-height: 1.875rem;

                    padding-bottom: 6.25rem;
                    padding-top: 1.5625rem;                   
                    margin-left: 3.25rem;
                }

                @media (max-width:900px) {
                    font-size: 1.2rem;
                    line-height: 1.575rem;

                    padding-bottom: 5rem;
                    padding-top: 1.25rem;
                    margin-left: 2.25rem;
                }

                @media (max-width:800px) {
                    font-size: 1.1rem;
                    line-height: 1.475rem;

                    padding-top: 0.9375rem;
                    padding-bottom: $space-normal;
                }

                @media (max-width:750px) {
                    max-width: 55%;
                }

                @media (max-width:700px) {
                    font-size: 1rem;
                    line-height: 1.375rem;

                    padding-bottom: 2.5rem;
                    padding-top: 0.625rem;                    
                }

                @media (max-width:600px) {
                    max-width: 58%;
                    
                    font-size: .9rem;
                    line-height: 1.275rem;

                    margin-left: 1.25rem;
                }
                @media (max-width:450px) {
                    max-width: 90%;

                    margin-left: 1.25rem;
                    font-size: 0.85rem;
                    line-height: 1.275rem;
                }
            }

            @media (max-width:1000px) {
                width: $content-width-responsive;
                margin-bottom: -2px;
            }
            @media (max-width:450px) {
                padding-top: $space-small;
            }
        }
        @media (max-width:700px){
            margin-top: 0;
        }
    }
}