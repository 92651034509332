@import "./../../styles/variables.scss";

.project-developers {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            position: relative;
            @media (max-width: 915px) {
                left: 0;
                margin-bottom: $space-small;
                justify-content: center;
            }

            @for $i from 2 to 15 {
                .developer:nth-child(#{$i}){
                    right:convert-unit($i - 1, 'rem');
                }
            }

            .developer:nth-child(4){
                @media (max-width:515px) {
                    right: 0!important;
                }
            }
            .developer:nth-child(5) {
                @media (max-width:515px) {
                    right: 1rem!important;
                }
            }
            .developer {
                color: #b5b8bf;
                font-weight: 600;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                width: 7rem;
                right: 0;
                @media (max-width: 413px) {
                    position: static;
                    right: 0;
                }

                img {
                    width: 7rem;
                    height: 7rem;
                    border-radius: 50%;
                    object-fit: cover;
                    border: 6px solid $color-light;
                }

                p {
                    color: #b5b8bf;
                    font-weight: lighter;
                    padding: 0!important;
                    margin: 0!important;
                    width: auto;
                    text-align: center;
                }
            }
            .others-developers {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;
                right: 3rem;
                border: 6px solid #FFFFFF;
                border-radius: 50%;
                height: 7rem;
                width: 7rem;
                background: #F1F3F4;    

                @media (max-width: 759px) {
                    right: 2rem;
                }
                @media (max-width:515px) {
                    right: 2rem !important;
                }

                @media (max-width: 413px) {
                    position: static;
                    right: 0;
                }

                h2 {
                    font-size: 2.2rem;
                    width: auto;
                    color: #b5b8bf;
                    margin: 0!important;
                    padding: 0!important;
                    line-height: 2.2rem;
                    text-align: center;
                }

                p {
                    width: auto;
                    color: #c7cbcf;
                    font-size: .7rem;
                    margin: 0!important;
                    padding: 0!important;
                    text-align: center;
                }
            }
        }