.cookies-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
}

.cookie-modal-window {
    position: fixed !important;
    right: 0;
    margin: 0 5%;
    padding: 0;
    bottom: 8.4444444444vh !important;
    width: 35rem;

    @media (max-width:630px) {
        width: 90%;
    }
}

.cookies-content {
    position: absolute;
    background: #FFFFFF;
    bottom: 7.4375rem;
    padding: 1.56rem 1.88rem 0 1.88rem;
    border: 1px solid #EDF0F3;
    border-bottom: none;

    @media (max-height:670px) {
        height: 103%;
        overflow-y: scroll;
    }

    @media (max-height:420px) {
        height: 80%;
    }

    @media (max-height:370px) {
        height: 40%;
    }
}

h5 {
    font-size: 1rem;
    color: #000000;
    letter-spacing: 0;
    padding: 2.44rem 0 1.25rem 0;
    margin: 0;

    @media (max-width:1000px) {
        font-size: 0.9rem;
        line-height: 0.9rem;
        padding: 2.2rem 0 1.10rem 0;
    }

    @media (max-width:630px) {
        font-size: 0.8rem;
        line-height: 0.8rem;
        padding: 1.8rem 0 0.9rem 0;
    }

    @media (max-width:400px) {
        font-size: 0.7rem;
        line-height: 0.7rem;
        padding: 1rem 0 0.6rem 0;
    }
}

p {
    font-size: 1rem;
    color: #828282;
    letter-spacing: 0;
    line-height: 1.25rem;
    padding: 0;
    margin: 0;
}

.more-cookies-text {
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
    font-weight: bold;
    font-size: 1rem;
    color: #828282;
    letter-spacing: 0;
    margin: 0.94rem 1.88rem 5.26rem 1.88rem;
}

.more-cookies {
    div {
        position: relative;
    }

    div:first-child {
        .switch {
            top: -0.375rem;
        }

        h5 {
            padding-top: 0;
        }
    }

    p {
        font-size: 0.875rem;
        color: #B4B8BF;
        letter-spacing: 0;
        line-height: 1rem;
        padding: 0;
        padding-bottom: 1.88rem;
        margin: 0;
        border-bottom: 0.06rem solid #EDF0F3;

        @media (max-width:1000px) {
            font-size: 0.8rem;
            line-height: 0.8rem;
            padding-bottom: 1.62rem;
        }

        @media (max-width:630px) {
            font-size: 0.7rem;
            line-height: 0.7rem;
            padding-bottom: 1.5rem;
        }

        @media (max-width:400px) {
            font-size: 0.65rem;
            line-height: 0.65rem;
            padding-bottom: 1.2rem;
        }
    }

    .cookies-last-p {
        padding-bottom: 0;
        border-bottom: none;
    }
}

.cookieConsent button {
    font-size: 1rem;
    width: 35rem;
    background-color: #EDF0F3;
    outline: none;
    border: none;
    height: 3.38rem;
    color: #FA124F;
    letter-spacing: 0;
    font-weight: bold;
    cursor: pointer;
    box-sizing: border-box;
    padding: 0;
    transition: .3s;
    position: fixed !important;
    right: 0;
    margin: 0 5%;
    bottom: 8.4444444444vh !important;
    width: 35rem;

    @media (max-width:630px) {
        width: 90%;
    }
}

button:hover,
button:focus {
    background-color: rgba($color: #000000, $alpha: .2);
}

.less-cookies {
    padding: 1.56rem 1.88rem 0 1.88rem;
    background: #FFFFFF;
    border: 1px solid #EDF0F3;
    border-bottom: none;
}

.cookies-settings-bg {
    background: #FFFFFF;
    /*position: absolute;
    bottom: 0;
    width: 100%;*/
}


.show-more-cookies {
    -webkit-animation: scale-in-ver-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-in-ver-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.hide-more-cookies {
    -webkit-animation: scale-out-ver-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-ver-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.hide-less-cookies {
    -webkit-animation: scale-out-ver-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-ver-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.hide-cookies-cover {
    -webkit-animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.show-cookies-cover {
    -webkit-animation: fade-in-fwd 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.cookies-link {
    font-size: 1rem;
    color: #828282;
    letter-spacing: 0;
    font-weight: bold;
    text-decoration: underline;
}

@keyframes scale-out-ver-bottom {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@keyframes scale-in-ver-bottom {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        -webkit-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        opacity: 1;
    }
}

@keyframes fade-out-bck {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 0.7;
    }

    100% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }
}

@keyframes fade-in-fwd {
    0% {
        -webkit-transform: translateZ(-80px);
        transform: translateZ(-80px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 0.7;
    }
}