@import './styles/variables.scss';
@import './styles/animate.scss';

@font-face {
  font-family: 'Strelsans';
  src: url('./assets/font/strelsans-light-webfont.woff') format('woff2'),
       url('./assets/font/strelsans-light-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Strelsans bold';
  src: url('./assets/font/strelsans-bold-webfont.woff2') format('woff2'),
       url('./assets/font/strelsans-bold-webfont.woff') format('woff'),
       url('./assets/font/strelsans-bold-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Strelsans extra-bold';
  src: url('./assets/font/strelsans-extrabold-webfont.woff2') format('woff2'),
    url('./assets/font/strelsans-extrabold-webfont.woff') format('woff'),
    url('./assets/font/strelsans-extrabold-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Strelsans italic';
  src: url('./assets/font/strelsans-italic-webfont.woff2') format('woff2'),
       url('./assets/font/strelsans-italic-webfont.woff') format('woff'),
       url('./assets/font/strelsans-italic-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Strelsans italic-bold';
  src: url('./assets/font/strelsans-bolditalic-webfont.woff2') format('woff2'),
       url('./assets/font/strelsans-bolditalic-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Strelsans regular';
  src: url('./assets/font/strelsans-regular-webfont.woff2') format('woff2'),
  url('./assets/font/strelsans-regular-webfont.ttf') format('truetype'),
    url('./assets/font/strelsans-regular-webfont.woff') format('woff');
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  max-width: 1920px;
  margin: 0 auto;
  font-family: 'Strelsans regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark-info-wrapper{
  background-color: $color-dark;
  width: 100%;
  .dark-page-info{
      width: 60.416666666666667%;
      margin: 0 auto;
      padding-top: $space-normal;
      h2{
          color: $color-light;
          margin: 0;
          margin-bottom: $space-small;
      }
      p{
          width: unset;
          color: #666666;
          margin: 0;
          padding-bottom: $space-small;
          a{
              color: $color-red!important;
          }
      }

      @media (max-width:1000px) {
          width: $content-width-responsive;
      }
  }
}

.light-info-wrapper{
  width: 100%;
}
.light-page-info{
    width: 60.416666666666667%;
    margin: 0 auto;
    padding-top: $space-normal;
    h2{
        color: $color-dark;
        margin: 0;
        margin-bottom: $space-small;
    }
    p{
        color: #666666;
        margin: 0;
        padding-bottom: $space-small;
        a{
            color: $color-red;
        }
    }

    @media (max-width:1000px) {
        width: $content-width-responsive;
    }
}


.text-small{
  font-size: $small-text-font-size;
  line-height: $small-text-line-height;

  @media (max-width:800px) {
    font-size: 1.15rem;
    line-height: 1.575rem;
  }

  @media (max-width:700px) {
    font-size: 1.05rem;
    line-height: 1.475rem;
  }

  @media (max-width:550px) {
    font-size: 0.95rem;
    line-height: 1.375rem;
  }
}

.text {
  font-size: $text-font-size;
  line-height: $text-line-height;
  @media (max-width:1300px) {
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  @media (max-width:1000px) {
    font-size: 1.3rem;
    line-height: 1.675rem;
  }

  @media (max-width:800px) {
    font-size: 1.25rem;
    line-height: 1.575rem;
  }

  @media (max-width:700px) {
    font-size: 1.15rem;
    line-height: 1.475rem;
  }

  @media (max-width:550px) {
    font-size: 1.05rem;
    line-height: 1.375rem;
  }
}

.link{
  font-size: $link-font-size;
  line-height:$link-line-height;
  text-decoration: none;

  @media (max-width:800px) {
    font-size: 1.15rem;
    line-height: 1.575rem;
  }

  @media (max-width:700px) {
    font-size: 1.05rem;
    line-height: 1.475rem;
  }

  @media (max-width:550px) {
    font-size: 0.95rem;
    line-height: 1.375rem;
  }
}

h1{
  font-family: 'Strelsans bold';
  font-size:$h1-font-size;
  line-height:$h1-line-height;
  font-weight: bold;
  letter-spacing: -1px;

  @media (max-width:1300px) {
    font-size:$h1-font-size - .2;
    line-height:$h1-line-height - .2;
  }

  @media (max-width:1160px) {
    font-size:$h1-font-size - .5;
    line-height:$h1-line-height - .5;
  }

  @media (max-width:900px) {
    font-size:$h1-font-size - .7;
    line-height:$h1-line-height - .7;
  }

  @media (max-width:800px) {
    font-size:$h1-font-size - .9;
    line-height:$h1-line-height - .9;
  }

  @media (max-width:700px) {
    font-size:$h1-font-size - 1.3;
    line-height:$h1-line-height - 1.3;
  }

  @media (max-width:615px) {
    font-size:$h1-font-size - 1.5;
    line-height:$h1-line-height - 1.5;
  }

  @media (max-width:550px) {
    font-size:$h1-font-size - 1.6;
    line-height:$h1-line-height - 1.6;
  }
}

h2 {
  font-family: 'Strelsans bold';
  font-size: $h2-font-size;
  line-height: $h2-line-height;
  letter-spacing: -0.5px;
  font-weight: bold;
  @media (max-width:1300px) {
    font-size: $h2-font-size - .2;
    line-height: $h2-line-height - .2;
  }

  @media (max-width:1160px) {
    font-size: $h2-font-size - .3;
    line-height: $h2-line-height - .3;
  }

  @media (max-width:900px) {
    font-size: $h2-font-size - .5;
    line-height: $h2-line-height - .5;
  }

  @media (max-width:700px) {
    font-size: $h2-font-size - .6;
    line-height: $h2-line-height - .6;
  }

  @media (max-width:615px) {
    font-size: $h2-font-size - .7;
    line-height: $h2-line-height - .7;
  }
}

p{
  letter-spacing: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
