@import './../../styles/variables.scss';

.dennikn-page{

    .dennikn-page-header-wrapper{
        width: 100%;
        background: url('./../../assets/Group 1@2x.png') no-repeat;
        background-position: center;
        background-size: cover;
        .dennikn-page-header{
            width: $content-width;
            margin: 0 auto;
            h1{
                color: $color-dark;
                padding-top: 10.625rem;
                margin: 0;
                @media (max-width:1300px) {
                    padding-top: $space-big - 1 + 3.25;
                }

                @media (max-width:1160px) {
                    padding-top: $space-big - 1.5 + 3.25;
                }

                @media (max-width:1000px) {
                    padding-top: $space-big - 2.5 + 3.25;
                }

                @media (max-width:800px) {
                    padding-top: $space-big - 3 + 3.25;
                }

                @media (max-width:700px) {
                    padding-top: $space-big - 3.5 + 3.25;
                }
                @media (max-width:550px) {
                    padding-top: $space-big - 4.5 + 3.25;
                }
                @media (max-width:450px) {
                    font-size: 2.1rem;
                    line-height: 2.475rem;
                    padding-top: $space-big - 5 + 3.25;
                }
            }
            p{
                opacity: 0.6;
                color: #2F2F2F;
                margin: 0;

                padding-top: $space-small;
                padding-bottom: $space-normal*5;

                @media (max-width:1160px) {
                    padding-bottom: $space-normal*4;
                }

                @media (max-width:1000px) {
                    padding-bottom: $space-normal*4;
                }

                @media (max-width:900px) {
                    padding-bottom: $space-normal*3;
                }

                @media (max-width:700px) {
                    padding-bottom: $space-normal*2.5;
                    padding-top: $space-small/2;
                }
            }
            @media (max-width:1000px) {
                width: $content-width-responsive;
            }
        }
        @media (max-width:1000px) {
            background-position: left;
        }
        @media (max-width:500px) {
            background-position: -60px 0;
        }
    }

    .dennikn-page-post-header{
        background-color: $color-dark;
        width: $content-width;
        margin: 0 auto;
        margin-top: -$space-normal;
        p{
            max-width: 48.73%;
            color: $color-light;

            padding: $space-big;
            padding-left: $space-big - 1.25;

            @media (max-width:1160px) {
                padding: $space-big - 1;
                padding-left: $space-normal;
                max-width: 55%;
            }
            @media (max-width:900px) {
                max-width: 80%;
                padding-bottom: 0;
                padding-top: $space-normal;
            }
            @media (max-width:700px) {
                padding: $space-normal 0;
                padding-bottom: $space-small;
                max-width: 80%;
                margin: 0 auto;
            }
            @media (max-width:600px) {
                padding: $space-small 0;
                padding-bottom: $space-small/2;
            }
            @media (max-width:450px) {
                font-size: 0.85rem;
                line-height: 1.275rem;
                text-align: justify;
            }
        }
        div{
            background: url("./../../assets/Artboard.svg");
            height: 410px;
            width: 270px;
            display: block;
            position: absolute;
            left: 65%;
            top: 54.6%;
            opacity: .5;
            @media (max-width:1300px) {
                width: 230px;
                height: 400px;
                top: 53%;
            }
            @media (max-width:1160px) {
                width: 230px;
                height: 370px;
                top: 45%;
            }
            @media (max-width:900px) {
                position: unset;
                margin: 0 auto;
                height: 95px;
                width: 90%;
                top: 0;
                left: 0;
                max-width: 85%;
                margin: 0 auto;
            }
            @media (max-width:700px) {
                height: 60px;
            }
            @media (max-width:600px) {
                height: 40px;
            }
        }
        @media (max-width:1000px) {
            width: $content-width-responsive;
        }
        @media (max-width:900px) {
            padding-bottom: $space-small;
        }
        @media (max-width:800px) {
            border-radius: 10px;
        }
    }

    .padding-bot-small{
        padding-bottom: $space-small!important;
    }
    .padding-top-small {
        padding-top: $space-small !important;
    }
    .dennikn-page-text-wrapper{
        width: $content-width;
        margin: 0 auto;
        padding-top: $space-big;
        padding-bottom: $space-big - 1.25;
        .dennikn-page-text{
            padding-left: $space-big + 5;
            max-width: 48.71%;
            h2{
                margin: 0;
                color: $color-dark;
            }
            p{
                color: $color-dark;
                opacity: 0.6;
                margin: 0;
                padding-top: $space-small;
                @media (max-width:450px) {
                    font-size: 0.85rem;
                    line-height: 1.275rem;
                    text-align: justify;
                }
            }
            .second-text{
                padding-top: $space-small/3;
            }
            .third-text{
                padding-top: $space-small/3;
            }
            @media (max-width:1300px) {
                padding-left: $space-big + 2;
                max-width: 60%;
            }
            @media (max-width:1160px) {
                padding-left: $space-big;
            }
            @media (max-width:1000px) {
                padding-left: $space-big - 2;
                max-width: 70%;
                width: $content-width;
            }
            @media (max-width:800px) {
                padding-left: $space-big - 4;
                max-width: 75%;
            }
            @media (max-width:700px) {
                padding-left: $space-normal - 1;
                max-width: 80%;
            }
            @media (max-width:600px) {
                padding-left: $space-normal - 2;
                max-width: 85%;
            }
            @media (max-width:550px) {
                max-width: 90%;
            }
            @media (max-width:500px) {
                max-width: 100%;
                margin: 0 auto;
                padding: 0;
            }
            @media (max-width:450px) {
                margin: 0 auto;
                width: 90%;
            }
            
        }
        @media (max-width:1000px) {
            width: $content-width-responsive;
        }
        @media (max-width:450px) {
            padding: $space-normal 0;
        }
    }

    .dennikn-page-editor-wrapper{
        background: url("./../../assets/kostolny-n@2x.png");
        background-size: cover;
        background-position: center;
        .dennikn-page-editor{
            width: $content-width;
            margin: 0 auto;
            display: flex;
            padding-top: $space-big;
            .editor-text-wrapper{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                max-width: 35.62%;
                margin-left: auto;
                p{
                    margin: 0;
                    color: $color-light;
                    a{
                        color: $color-red;
                        display: inline-block;
                    }
                    @media (max-width:450px) {
                        font-size: 0.85rem;
                        line-height: 1.275rem;
                    }
                }
                p:nth-child(1){
                    padding-bottom: $space-small/3;
                }
                p:nth-child(2) {
                    padding-bottom: $space-small/3;
                    @media (max-width:1400px) {
                        text-align: justify;
                    }
                }
                p:nth-child(3){
                    padding-bottom: $space-small;
                }
                p:nth-child(4){
                    opacity: .6;
                    padding-bottom: .1rem;
                }
                small{
                    color: $color-light;
                    opacity: .6;
                    padding-bottom: $space-small;
                }
                img{
                    padding-bottom: $space-normal*5;
                    @media (max-width:1400px) {
                        padding-bottom:$space-normal*3;
                    }
                    @media (max-width:900px) {
                        padding-bottom:$space-normal*2;
                    }
                    @media (max-width:600px) {
                        padding-bottom:$space-normal*1.5;
                    }
                    @media (max-width:450px) {
                        padding-bottom:$space-normal;
                    }
                }
                @media (max-width:1400px) {
                    max-width: 40%;
                }
                @media (max-width:1300px) {
                    max-width: 45%;
                }
                @media (max-width:1160px) {
                    max-width: 60%;
                }
                @media (max-width:1000px) {
                    max-width: 70%;
                }
                @media (max-width:900px) {
                    max-width: unset;
                    width: 80%;
                    margin: 0 auto;
                    width: 90%;
                    background-position: left;
                }
            }
            @media (max-width:1000px) {
                width: $content-width-responsive;
            }
            @media (max-width:900px) {
                padding-top: $space-normal*1.5;
            }
            @media (max-width:600px) {
                padding-top:$space-normal;
            }
            @media (max-width:450px) {
                padding-top:$space-small;
            }

        }
        @media (max-width:900px) {
            background-position: left;
        }
    }

    .dennikn-page-img{
        width: 67.63%;
        margin: 0 auto;
        img{
            max-width: 100%;
        }
        @media (max-width:1300px) {
            width: 75%;
        }
        @media (max-width:1160px) {
            width: 80%;
        }
        @media (max-width:1000px) {
            width: 85%;
        }
        @media (max-width:800px) {
            width: 90%;
        }
        @media (max-width:450px) {
            width: 100%;
        }
    }
    .dennikn-page-img-2{
        width: 67.63%;
        margin: 0 auto;
        margin-bottom: -$space-normal;
        img{
            max-width: 100%;
        }
        @media (max-width:1300px) {
            width: 75%;
        }
        @media (max-width:1160px) {
            width: 80%;
        }
        @media (max-width:1000px) {
            width: 85%;
        }
        @media (max-width:800px) {
            width: 90%;
        }
        @media (max-width:450px) {
            width: 95%;
        }
    }

    .dennikn-page-post-content{
        display: flex;
        background-color: $color-dark;
        margin: 0 auto;
        .dennikn-page-post-content-flex{
            width: 67.63%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding-top: $space-big - 1.25;
            padding-bottom: $space-normal - 1.25;
            .post-content-text-wrapper{
                max-width: 58.76%;
                h2{
                    color: $color-light;
                }
                p{
                    color: $color-light;
                    opacity: .6;
                    @media (max-width:450px) {
                        font-size: 0.85rem;
                        line-height: 1.275rem;
                        text-align: justify;
                    }
                }
                @media (max-width:800px) {
                    max-width: 80%;
                    padding-left: $space-big - 4;
                    padding-bottom: $space-small;
                }
                @media (max-width:600px) {
                    padding-left: $space-normal - 2;
                }
                @media (max-width:450px) {
                    margin: 0 auto;
                    max-width: 90%;
                    padding: 0;
                }
            }
            .dot-pattern{
                background: url("./../../assets/Artboard.svg");
                height: 410px;
                width: 270px;
                display: block;
                opacity: .5;
                align-self: flex-end;
                @media (max-width:1300px) {
                    height: 370px;
                }
                @media (max-width:1160px) {
                    height: 330px;
                    width: 217px;
                }
                @media (max-width:1000px) {
                    height: 330px;
                }
                @media (max-width:800px) {
                    height: 95px;
                    width: 90%;
                    margin: 0 auto;
                }
                @media (max-width:700px) {
                    height: 80px;
                }
                @media (max-width:600px) {
                    height: 65px;
                }
            }
            @media (max-width:1300px) {
                width: 75%;
            }
            @media (max-width:1160px) {
                width: 80%;
            }
            @media (max-width:1000px) {
                width: 85%;
            }
            @media (max-width:800px) {
                width: 90%;
                flex-wrap: wrap;
            }
        }

    }

    .dennikn-team-members-wrapper{
        margin: 0 auto;
        width: $content-width;
        @media (max-width:1000px) {
            width: $content-width-responsive;
        }

    }
    
}