@import "./../../styles/variables.scss";

$text-color:#828282;
.footer-dark{
    background-color: #000000;
    .footer-map{
        background: url("./../../assets/map.svg");
        background-position:center!important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        position: relative !important;
        .footer-gradient-bg-top{
            background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
            height: 5*$space-normal;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            @media(max-width:1000px) {
                height: 4*$space-normal;
            }
            @media(max-width:800px) {
                height: 3*$space-normal;
            }
            @media(max-width:600px) {
                height: 1.5*$space-normal;
            }
            @media(max-width:450px) {
                height: $space-normal;
            }
        }
        .footer-gradient-bg-bottom {
            background-image: linear-gradient(179deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
            height: 5*$space-normal;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            @media(max-width:1000px) {
                height: 4*$space-normal;
            }
            @media(max-width:800px) {
                height: 3*$space-normal;
            }
            @media(max-width:600px) {
                height: 1.5*$space-normal;
            }
            @media(max-width:450px) {
                height: $space-normal;
            }
        }
    }
}
.footer{
    width: 100%;
    .footer-map{
        background: url("./../../assets/map.svg");
        background-position:center;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        padding: $space-normal*5 0;
        
        // background-image: linear-gradient(179deg, rgba(0, 0, 0, 0.00) 0%, #000000 100%);
        p{
            color:$color-light;
            margin: 0;
            @media (max-width:600px) {
                font-size: .8rem;
            }
        }

        .sandiego-office{
            position: absolute;
            top: 44.8%;
            left: 28%;
            border: 1px solid $text-color;
            background-color: #000;
            padding: .7rem;
            img {
                display: none;
                @media (max-width:900px) {
                    display: block;
                    width: 20px;
                    left: 55.6%;
                }
                @media (max-width:600px) {
                    width: 15px;
                }
                @media (max-width:400px) {
                    width: 10px;
                }
            }
            @media only screen and (max-width: 1920px) and (min-width: 1700px) {
                left: 33%;
            }
            @media only screen and (max-width: 1700px) and (min-width: 1600px) {
                left: 32%;
            }
            @media only screen and (max-width: 1600px) and (min-width: 1500px) {
                left: 30%;
            }
            @media (max-width:1300px) {
                left: 26%;
            }
            @media (max-width:1200px) {
                left: 25%;
            }
            @media (max-width:900px) {
                border: none;
                background-color: transparent;
                left: 21%;
                top: 40%;
            }
            @media (max-width:850px) {
                left: 20%;
            }
            @media (max-width:800px) {
                left: 19%;
            }
            @media (max-width:600px) {
                left: 24%;
                top: 36%;
            }
            @media (max-width:550px) {
                left: 22%;
            }
            @media (max-width:500px) {
                left: 18.5%;
            }
            @media (max-width:400px) {
                top: 38%;
                left: 18.2%;
            }
            p::before {
                content: url("./../../assets/pin.svg");
                position: absolute;
                top: -22px;
                left: -25px;
                @media (max-width:900px) {
                    display: none;
                }
            }
            p{
                position: relative;
                @media (max-width:900px) {
                    display: none;
                }
                @media (max-width:400px) {
                    max-width: 5rem;
                    line-height: unset;
                }
            }

        }
        .prague-office{
            position: absolute;
            display: flex;
            flex-direction: column;
            top: 19.5%;
            left: 40.9%;
            border: 1px solid $text-color;
            padding: .7rem;
            background-color: #000;
            img {
                display: none;
                @media (max-width:900px) {
                    display: block;
                    width: 20px;
                }
                @media (max-width:600px) {
                    width: 15px;
                }
                @media (max-width:400px) {
                    width: 10px;
                }
            }
            small{
                color: #666666;
                font-size: 1rem;
                @media (max-width:900px) {
                    display: none;
                }
            }
            p{
                position: relative;
                @media (max-width:900px) {
                    display: none;
                }
            }
            p::after{
                content: url("./../../assets/pin.svg");
                position: absolute;
                bottom: -70px;
                right: -21px;
                @media (max-width:900px) {
                    display: none;
                }
            }
            @media only screen and (max-width: 1920px) and (min-width: 1500px) {
                left: 40.9%;
            }
            @media (max-width:1800px) {
                left: 40%;
            }
            @media (max-width:1700px) {
                left: 39%;
            }
            @media (max-width:1500px) {
                left: 37.7%;
            }
            @media (max-width:1400px) {
                left: 36.6%;
            }
            @media (max-width:1300px) {
                left: 35.5%;
            }
            @media (max-width:1200px) {
                left: 34.4%;
            }
            @media (max-width:1100px) {
                left: 33%;
                top: 21%;
            }
            @media (max-width:1000px) {
                left: 31.5%;
                top: 16%;
            }
            @media (max-width:900px) {
                border: none;
                background-color: transparent;
                left: 48%;
                top: 29.5%;
            }
            @media (max-width:700px) {
                left: 47%;
                top: 31.5%;
            }
            @media (max-width:600px) {
                left: 47.4%;
                top: 25.5%;
            }
            @media (max-width:400px) {
                left: 46.7%;
                top: 29.7%;
            }
            @media (max-width:350px) {
                top: 31%;
            }
        }
        .bratislava-office{
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 36.9%;
            left: 51.7%;
            border: 1px solid $color-red;
            padding: .7rem;
            background-color: #000;
            img{
                display: none;
                @media (max-width:900px) {
                    display: block;
                    width: 20px;
                    left: 55.6%;
                }
                @media (max-width:600px) {
                    width: 15px!important;
                }
                @media (max-width:400px) {
                    width: 10px!important;
                }
            }
            p{
                position: relative;
                @media (max-width:900px) {
                    display: none;
                }
            }
            p::before{
                content: url("./../../assets/pin.svg");
                position: absolute;
                top: -22px;
                left: -25px;
                @media (max-width:900px) {
                    display: none;
                }
            }
            small{
                color: #666666;
                font-size: 1rem;
                @media (max-width:900px) {
                    display: none;
                }
            }
            @media only screen and (max-width: 1920px) and (min-width: 1700px) {
                left: 51.7%;
            }
            @media (max-width:1700px) {
                left: 51.3%;
            }
            @media (max-width:1600px) {
                left: 52.3%;
            }
            @media (max-width:1500px) {
                left: 51.7%;
            }
            @media (max-width:1400px) {
                left: 51.8%;
            }
            @media (max-width:1300px) {
                left: 51.7%;
            }
            @media (max-width:1200px) {
                left: 51.7%;
            }
            @media (max-width:1150px) {
                left: 52.7%;
            }
            @media (max-width:1100px) {
                left: 52%;
                top: 38%;
            }
            @media (max-width:950px) {
                left: 53.2%;
            }
            @media (max-width:900px) {
                border: none;
                background-color: transparent;
                left: 50.2%;
                top: 33%;
            }
            @media (max-width:700px) {
                top: 35%;
            }
            @media (max-width:600px) {
                left: 50%;
                top: 30.3%;
            }
            @media (max-width:400px) {
                left: 49.3%;
                top: 32.3%;
            }
        }
        @media (max-width:1000px) {
            padding: $space-normal*4 0;
        }
        @media (max-width:900px) {
            padding: $space-normal*3.5 0;
        }
        @media (max-width:600px) {
            padding: $space-normal*2 0;
        }
    }
    .logo {
        padding-top: 3.75rem;
        width: 100;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ul{
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding-top: 1.88rem;
        padding-bottom: 3.75rem;
        li{
            padding: 0 1.25rem;
            padding-bottom: 0.8%;
            font-size: 1rem;
            letter-spacing: 0;
            list-style: none;
            display: inline-block;
            a{
                text-decoration: none;
                color: $text-color;
                transition: color 0.3s ease;
            }
            a:hover{
                color:#FA124F;
            }
            @media (max-width:700px){
                font-size: .8rem;
            }
            @media (max-width:500px) {
                font-size: .7rem;
            }
            @media (max-width:450px) {
                padding: 0 0.6rem;
                font-size: .6rem;
            }
        }
        @media (max-width:700px) {
            padding-bottom: 2.5rem;
        }
    }
}
