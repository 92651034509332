@function convert-unit($value, $unit) {
  $units: ('px': 0px,
      '%': 0%,
      'em': 0em,
      'rem': 0rem,
      'ms': 0ms
    );

    @if map-has-key($units, $unit) {
      @return map-get($units, $unit)+$value;
    }
}

//            WIDTHS

$content-width:convert-unit(81.25, '%');
$content-width-responsive:convert-unit(90, '%');


//            PADDINGS AND MARGINS

$space-normal: convert-unit(60/16, 'rem');
$space-big: convert-unit(120/16, 'rem');
$space-small: convert-unit(30/16, 'rem');

//            FONTS AND LINE HIEGHTS

$h1-font-size:convert-unit(64/16, 'rem');
$h1-line-height:convert-unit(70/16, 'rem');

$h2-font-size:convert-unit(46/16, 'rem');
$h2-line-height:convert-unit(54/16, 'rem');

$small-text-font-size:convert-unit(20/16, 'rem');
$small-text-line-height:convert-unit(26/16, 'rem');

$text-font-size:convert-unit(24/16, 'rem');
$text-line-height:convert-unit(30/16, 'rem');

$link-font-size:convert-unit(20/16, 'rem');
$link-line-height:convert-unit(26/16, 'rem');

//               COLORS

$color-dark: #000000;
$color-light: #ffffff;
$color-red: #FA124F;
$color-grey: #828282;