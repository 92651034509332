@import '../../styles/variables.scss';
body{
    margin: 0 auto;
}

.icewarp-page{
    p,h2{
        width: 48.72%;
    }
}
.icewarp-header-wrapper{
    height: 50.13rem;
    .icewarp-header-content{
        position: relative;
        margin: 0 auto;
        width: $content-width;
        height: 50.13rem;
        div{
            padding-top: 8.13rem;
            width: 100%;
            p{
                width: 35%;
                color: $color-dark;
                opacity: 0.6;
            }
        }
        img:nth-child(2){
            position: absolute;
            width: 53.33%;
            right: 22.24rem;
            top: 26.63rem;
            z-index: 3;
        }
        img:nth-child(3){
            z-index: 2;
            position: absolute;
            width: 57.26%;
            right: 0;
            top: 8.13rem;
        }
    } 
}
.icewarp-about-wrapper{
    background:$color-dark;
    width: 100%;
    height: 23.13rem;
    .icewarp-about-content{
        position: relative;
        width: $content-width;
        height: 23.13rem;
        margin: 0 auto;
        p{
            position: relative;
            padding-left: 8.55%;
            padding-top: $space-big + 1rem;
            color: $color-light;
            z-index: 4;
        }
        img{
            width: 7.61%;
            position: absolute;
            top: -8.8%;
            left: 8.55%;
        }
        div{
            opacity: 0.5;
            position: absolute;
            top: -6.25rem;
            z-index: 3;
            right: 5.26%;
            width: 16.88rem;
            height: 25.63rem;
            background-image: url('../../assets/dot_pattern_orange.svg');
        }
    }
}
.icewarp-maincontent-wrapper{
    width: 100%;
    .icewarp-maincontent-content{
        position: relative;
        width: $content-width;
        margin: 0 auto;
        h2{
            padding-top: $space-big;
            padding-left: 12.5rem;
            margin-bottom: 0;
        }
        p{
            margin-top: $space-small;
            margin-bottom: $space-normal;
            padding-left: 12.5rem;
            color: $color-grey;
        }
        #message{
            z-index: 2;
            position: absolute;
            width: 59.49%;
        }
        .icewarp-dotpattern{
            top: 1.88rem;
            width: 23.08%;
            height: 25.63rem;
            background-image: url('../../assets/dot_pattern_orange.svg');
            position: relative;
            left: 54.5%;
            opacity: 0.5;
        }
        .files{
            height: 78px;
            width: 430px;
            display: flex;
            margin-top: -5rem;
            flex-direction: row;
            align-items: center;
            position: absolute;
            right: 0;
            justify-content: space-between;
            div{
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20%;
                width: 78px;
                background: #f4f4f4;
                height: 78px;
            }       

        }
    }
}
.icewarp-result-wrapper{
    background: $color-dark;
    height: 51.69rem;
    width: 100%;
    .icewarp-result-content{
        position: relative;
        height:51.69rem ;
        width: $content-width;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        h2{
            margin: 0;
            margin-bottom: $space-normal;
            padding: 0;
            color: $color-light;
        }
        p{
            margin: 0;
            padding: 0;
            color: $color-grey;
        }
        img{
            position: absolute;
            top: 2rem;
            right: 2rem;
            flex-basis: 100%;
            width: 37.53%;
        }
    }
}


@media (min-width: 1500px){
    .icewarp-header-wrapper{
        .icewarp-header-content{
            img:nth-child(3){
                width: 46.26%;
                top: 6rem;
            }
            img:nth-child(2){
                width: 42.26%;
            }
        }
    }
    .icewarp-result-wrapper{
        .icewarp-result-content{
            img{
                width: 27%;
            }
        }
    }
    
    }
@media(max-width: 1000px){
    .icewarp-header-wrapper{
        .icewarp-header-content{
            width: $content-width-responsive;
        }
    }
    .icewarp-about-wrapper{
        .icewarp-about-content{
            width: $content-width-responsive;
        }
    }
    .icewarp-maincontent-wrapper{
        .icewarp-maincontent-content{
            width: $content-width-responsive;
        }
    }
    .icewarp-result-wrapper{
        .icewarp-result-content{
            width: $content-width-responsive;
        }
    }
}
@media (max-width: 1300px){
    .icewarp-header-wrapper{
        height: 40rem;
        .icewarp-header-content{
            img:nth-child(2){
                width: 46.26%;
            }
            img:nth-child(3){
                width: 46.26%;
            }
        }
    }
}
@media (max-width: 1150px){
    .icewarp-header-wrapper{
        height: 43rem;
        .icewarp-header-content{
            img:nth-child(2){
                width: 56.26%;
            }
            img:nth-child(3){
                width: 61.26%;
            }
        }
    }

    .icewarp-result-wrapper{
        .icewarp-result-content{
        width: $content-width-responsive;
        flex-wrap: unset;
        justify-content: flex-start;
        align-items: center;
        h2{
            width: 70%;
            padding-top: $space-small;
            margin-bottom: $space-small;
        }
        p {

            width: 70%;
        }
        img{
            position: relative;
            width: 37.53%;
            flex-basis: 0%;
            right: 0;
        }
        }
    }
}
@media (max-width: 830px){
    .icewarp-header-wrapper{
        height: 35rem;
        .icewarp-header-content{
            height: 35rem;
            width: $content-width-responsive;
            div{
                padding-top:  6.5rem;
            }
            img:nth-child(2){
                top:55%;
                width: 43.26%;
                right: 30%;
            }
            img:nth-child(3){
                top: 6.5rem;
                width: 60.26%;
            }
        }
    }
    .icewarp-about-wrapper{
        .icewarp-about-content{
            width: $content-width-responsive;
            p{
                width: 80%;
                padding-left: 0;
            }
            img{
                left: 0;
                width: 14.61%;
                top: -5.8%;
            }
            div{   
                top: 3rem;
                z-index: 3;
                right: 0;
                width: 10rem;
                height: 15rem;
            }
        }
    }
    .icewarp-maincontent-wrapper{
        .icewarp-maincontent-content{
            width: $content-width-responsive;
            h2{
                padding-top: $space-small;
                padding-left: 0rem;
            }
            p{
                padding-left: 0rem;
                width: 80%;
            }
            #message{
                width: 59.49%;
            }
            .icewarp-dotpattern{
                height: 20.63rem;
            }
        }
    }
}
@media (max-width: 650px){
    .icewarp-header-wrapper{
        height: 23rem;
        .icewarp-header-content{
            height: 23rem;
            div{
                padding-top: 5rem;
            }
            img:nth-child(2){
                width: 40%;
                top: 60%;
            }
            img:nth-child(3){
                top: 5rem;
            }
        }
    }
    .icewarp-maincontent-wrapper{
        .icewarp-maincontent-content{
            .files{
                position: relative;
                width: 100%;
                margin-top: -9rem;
                flex-wrap: wrap;
                justify-content: center;
                div{
                    margin:  .25rem;
                }
            }
        }
    }
    .icewarp-result-wrapper{
        height: 40rem;
    }
}
@media (max-width: 500px){
    .icewarp-header-wrapper{
        height: 20rem;
        .icewarp-header-content{
            height: 20rem;
            width: $content-width-responsive;
            img:nth-child(2){
                top:69%;
                width: 43.26%;
                right: 30%;
            }
            img:nth-child(3){
                top: 5rem;
                width: 58.26%;
            }
        }
    }
    .icewarp-about-wrapper{
        .icewarp-about-content{
            p,h2{
                width: 100%;
            }
        }
    }
    .icewarp-maincontent-wrapper{
        .icewarp-maincontent-content{
            p,h2{
                width: 100%;
            }
            .files{
                position: relative;
                width: 100%;
                margin-top: -9rem;
                flex-wrap: wrap;
                justify-content: center;
                div{
                    width: 50px;
                    height: 50px;
                    margin:  .25rem;
                    img{
                        width: 25px;
                    }
                }
            }
        }
    }
    .icewarp-result-wrapper{
        height: 35rem;
        .icewarp-result-content{
            p,h2{
                width: 100%;
            }
        }
    }
}
@media(max-width: 390px) {
    .icewarp-header-wrapper{
        height: 17rem;
        .icewarp-header-content{
            height: 17rem;
            div{
                padding-top: 3rem;
                h1{
                    font-size: 1.8rem;
                }
                p{
                    font-size: .8rem;
                }
            }
        }
    }
    .icewarp-result-wrapper{
        height: 32rem;
    } 
}