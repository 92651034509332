    body{
    margin: 0 auto;
}
@import '../../styles/variables.scss';
.surglogs-welcome-wrapper{
    height:37.94rem ;
    width: 100%;
    background-image: url('../../assets/sl1@2x.png');
    background-position: center;
    background-size:cover;
    .surglogs-welcome-content{
        width: $content-width;
        margin: 0 auto;
        h1{
            padding-top: 10.75rem;
            margin-top: 0;
        }
        p{
            color: $color-grey;
        }
    }
}
.surglogs-page{
    overflow: hidden;
}
.surglogs-start-wrapper{
    position: relative;
    width: $content-width;
    margin: -3.75rem auto;
    height: 26.63rem;
    background:$color-dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    p{
        margin: 0;
        padding: 1.3rem 0;
        position: relative;
        z-index: 2;
        background: black;
        padding-left: 6.25rem;
        color: $color-light;
        width: 48.71%;
    }
    p:nth-child(2){
        color: $color-grey;
    }
    .surglogs-dotpattern{
        margin: 0;
        padding: 0;
        position: absolute;
        right: 3.75rem;
        top: 4.75rem;
        opacity: 0.5;
        background-image: url(../../assets/dot_pattern_blue.svg);
        flex-basis: 100%;
        width: 16.88rem;
        height: 25.63rem;
        
    }
}
.surglogs-challange-wrapper{
    padding-top: 7.5rem;
    margin-top: 3.75rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    .surglogs-iphones-image{
        margin: 8.57rem auto;
        width:100%;
    }
    .surglogs-teammembers{
        position: relative;
        left: 9rem;
        width: $content-width;
        margin: 3rem auto;
        @media (max-width: 1600px) {
            left: 0;
        }
        @media (max-width: 1000px) {
            width: $content-width-responsive;
        }
    }
    .surglogs-challange-content{
        position: relative;
        margin: 0 auto;
        width: 67.33%;
        img{
            padding: 3.75rem 0;
        }
        h2{
            margin-top: 0;
            margin-left: 3.57rem;
            width: 58.82%;
        }
        p{
            margin: 0;
            color: $color-grey;
            width: 58.82%;
            margin:0 3.57rem;
    }
        
        .surglogs-smaller-img{
            width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .surglogs-smaller-img:last-child{
            position: relative;
            z-index: 3;
            margin-bottom: -7.5rem;
        }
    }
}
.surglogs-result-wrapper{
    position: relative;
    width: 100%;
    height: 33.75rem;
    background:$color-dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    div{
        position: relative;
        margin: 0 auto;
        position: relative;
        margin: 0 auto;
        width: 67.33%;
        h2{
            color: $color-light;
        }
        p{
            color: $color-grey;
            max-width: 35.63rem;
        }
        div{
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0rem;
        opacity: .5;
        background-image: url(../../assets/dot_pattern_blue.svg);
        flex-basis: 100%;
        width: 16.88rem;
        height: 25.63rem;
        }
    }
}
@media (max-width: 1600px){
    .surglogs-challange-wrapper{
        .surglogs-challange-content{
            width: 80%;
            h2{
               
                width: 60%;
            }
            p {
                width: 60%;
            }
        }  
    }

    .surglogs-result-wrapper{
        div {
            p{
                position: relative;
                z-index: 2;
                background:$color-dark;
            }
            div{
                width: 30%;
                height: 23rem;
                top: 0rem
            }
        }  
    }
    .surglogs-start-wrapper{
        p{
            width: 50%;
        }
    }
}
@media (max-width: 1300px){
    .surglogs-challange-wrapper{
        padding-top: 2rem;
        .surglogs-smaller-img{
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .surglogs-iphones-image{
            margin: 4rem auto ;
        }
        .surglogs-challange-content{
            width: 80%;
            h2{
                margin-left: auto;
                margin-right: auto;
                width: 80%;
            }
            p {
                margin: 0 auto;
                width: 80%;
            }
        }  
    }

    .surglogs-result-wrapper{
        div {
            p{
                position: relative;
                z-index: 2;
                background:$color-dark;
            }
            div{
                width: 30%;
                height: 23rem;
                top: 0rem
            }
        }  
    }
    .surglogs-start-wrapper{
        p{
            width: 50%;
        }
    }
}
@media (max-width: 1050px){
    .surglogs-start-wrapper{
        width: 95%;
    }
    .surglogs-result-wrapper{
        div {
            div{
                width: 40%;
            }
        }  
    }
}
@media (max-width: 700px){
    .surglogs-start-wrapper{
        width: 95%;
        p{
            padding-left: 0;
            margin: 0 auto;
            width: 80%;
        }
    }
    .surglogs-challange-wrapper{
        .surglogs-challange-content{
            width: 95%;
            h2{
                margin-left: auto;
                margin-right: auto;
                width: 90%;
            }
            p {
                margin: 0 auto;
                width: 90%;
            }
        }  
    }
    .surglogs-result-wrapper{
        div {
            p{
                position: relative;
                z-index: 2;
                background:$color-dark;
            }
            div{
                width: 100%;
                height: 4rem;
                top: 24rem
            }
        }  
    }
}
@media (max-width: 500px){

    .surglogs-start-wrapper{
        p{
            width: 90%;
        }
        .surglogs-dotpattern{
            height: 23rem;
            width: 80%;
            right: 10%;
            top: 4rem;
         }
    }
    .surglogs-welcome-wrapper{
        height:30rem ;
        background-position-x: -23rem
    }
    .surglogs-result-wrapper{
        div {
            width: 90%;
        } 
        .surglogs-dotpattern{
            top: 20rem
        }
    }

}
