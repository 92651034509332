@import './../../styles/variables.scss';

.careers-page {
    .careers-page-post-header-wrap {
        background-color: $color-dark;
        .careers-page-post-header {
            width: $content-width;
            margin: 0 auto;
            h1 {
                color: $color-light;
                max-width: 65%;
                
                padding-top: 10.625rem;
                padding-bottom: $space-small*9;
                margin: 0;

                @media (max-width:1400px) {
                    max-width: 68%;

                }

                @media (max-width:1300px) {
                    max-width: 73%;

                    padding-top: $space-big - 1 + 3.25;
                    padding-bottom: $space-small*8;
                }

                @media (max-width:1160px) {
                    max-width: 78%;

                    padding-top: $space-big - 1.5 + 3.25;
                    padding-bottom: $space-small*7.5;
                }

                @media (max-width:1000px) {
                    max-width: 88%;

                    padding-top: $space-big - 2.5 + 3.25;
                    padding-bottom: $space-small*6.5;
                }

                @media (max-width:900px) {
                    max-width: 90%;
                }

                @media (max-width:800px) {
                    max-width: 97%;

                    padding-top: $space-big - 3 + 3.25;
                    padding-bottom: $space-small*6;
                }

                @media (max-width:700px) {
                    max-width: 100%;

                    padding-top: $space-big - 3.5 + 3.25;
                    padding-bottom: $space-small*5.5;
                }
                @media (max-width:550px) {
                    padding-top: $space-big - 4.5 + 3.25;
                    padding-bottom: $space-small*4.5;   
                }
                @media (max-width:450px) {
                    font-size: 2.1rem;
                    line-height: 2.475rem;
                    padding-top: $space-big - 5 + 3.25;
                    padding-bottom: $space-small*4;
                }
            }
            @media (max-width:1000px) {
                max-width: $content-width-responsive;
            }
        }
    }

    .careers-page-content{
        background-color: $color-light;
        position: relative;
        width: 67.361111111111111111111111111111%;
        
        margin: 0 auto;
        margin-top: -$space-small*5;
        section{
            h2{
                color: $color-dark;

                margin-top: 0;
                padding: 0 $space-normal;
                padding-top: $space-normal;

                @media (max-width:700px) {
                    padding: 0 $space-normal - 1;
                    padding-top: $space-normal - 1;
                }
                @media (max-width:600px) {
                    padding-top: $space-normal - 2;
                }
                @media (max-width:450px) {
                    padding-top: $space-normal - 3;
                    padding: 0 $space-normal - 2.2;
                    font-size: 1.975rem;
                    line-height: 2.475rem;
                    margin: 0;
                }
            }
            p{
                opacity: 0.6;
                color: $color-dark;

                padding: 0 $space-normal;

                @media (max-width:700px) {
                    padding: 0 $space-normal - 1;
                }
                @media (max-width:450px) {
                    padding: 0 $space-normal - 2.2;
                    font-size: 0.85rem;
                    line-height: 1.275rem;
                }
            }
            a{
                color: #FA124F;
                display: inline-block;
                padding-bottom: .1rem;
                margin-left: $space-normal;

                @media (max-width:700px) {
                    margin-left: $space-normal - 1;
                }
                @media (max-width:450px) {
                    margin-left: $space-normal - 2.2;
                    font-size: 0.85rem;
                    line-height: 1.275rem;
                }
            }

            a:after {
                content: '';
                display: block;
                border-bottom: 2px solid #FA124F;
                width: 0;
                position: relative;
                transition: .3s cubic-bezier(.22,.68,0,1.1);
            }

            a:hover:after {
                width:100%;
            }
        }
        section:last-child{
            padding-bottom: $space-normal;
            @media (max-width:700px) {
                padding-bottom: $space-normal - 1;
            }

            @media (max-width:600px) {
                padding-bottom: $space-normal - 2;
            }
        }

        @media (max-width:1300px) {
            width: 73%;
            margin-top: -$space-small*4;
        }

        @media (max-width:1160px) {
            width: $content-width;
            margin-top: -$space-small*3.7;
        }

        @media (max-width:1000px) {
            width: $content-width-responsive;
            margin-top: -$space-small*3.4;
        }

        @media (max-width:800px) {
            margin-top: -$space-small*3.1;
        }

        @media (max-width:700px) {
            margin-top: -$space-small*2.8;
        }

        @media (max-width:550px) {
            margin-top: -$space-small*2.5;
        }
        @media (max-width:450px) {
            padding-top: $space-normal - 3;
        }
    }



    .careers-dots{
        width: 72%;
        position: relative;
        z-index: -1;

        margin: 0 auto;
        margin-top: -0.7rem;
        padding-bottom: $space-normal; 
        img{
            width: 100%;
        }

        @media (max-width:1300px) {
            width: 78%;
        }

        @media (max-width:1160px) {
            width: 89.25%;
            margin-top: -.8rem; 
        }

        @media (max-width:1000px) {
            width: 95%;
            margin-top: -0.5rem;
        }
        @media (max-width:700px) {
            padding-bottom: $space-normal - 1;
        }

        @media (max-width:600px) {
            padding-bottom: $space-normal - 2;
        }
    }

    .careers-page-texts {
        width: $content-width;

        padding: $space-normal 0;
        margin: 0 auto;
        p {
            color: $color-dark;
            max-width: 48.717948717948718%;
            opacity: 0.6;
 
            margin: 0;
            margin-left: 7rem;

            @media only screen and (max-width: 1600px) and (min-width: 1500px) {
                max-width: 45.717949%;
                margin-left: 13.25rem;
            }

            @media only screen and (max-width: 1700px) and (min-width: 1600px) {
                max-width: 40.717949%;
                margin-left: 17.9375rem;
            }

            @media only screen and (max-width: 1800px) and (min-width: 1700px) {
                max-width: 36.717949%;
                margin-left: 18.9375rem;
            }

            @media only screen and (max-width: 1920px) and (min-width: 1800px) {
                max-width: 36.717949%;
                margin-left: 20.9375rem;
            }

            @media (max-width:1160px) {
                max-width: 54%;
                margin-left: 4rem;
            }

            @media (max-width:1000px) {
                max-width: 70%;
                margin-left: 3rem;
            }

            @media (max-width:810px) {
                margin-left: 2rem;
            }

            @media (max-width:700px) {
                max-width: 90%;
            }

            @media (max-width:650px) {
                margin-left: 1rem;
            }

            @media (max-width:600px) {
                margin-left: 1rem;               
            }
        }

        p:first-child {
            padding-bottom: $space-small;
            @media (max-width: 1000px) {
                padding-bottom: $space-small/2;
            }
        }
        @media (max-width: 1000px) {
            width: $content-width-responsive;
        }
        @media (max-width:700px) {
            padding-top: $space-normal - 1;
        }

        @media (max-width:600px) {
            padding: $space-small - 1 0;
            padding-top: $space-normal - 2;
        }
    }


    .careers-page-post-content-wrap {
        background-color: $color-dark;
        margin-top: 3rem;
        margin-bottom: -5px;
        .careers-page-post-content {
            width: $content-width;

            padding-top: $space-normal;            
            margin: 0 auto;
            .careers-page-post-content-photos {
                width: 100%;
                height: 100%;
                display: flex;
                img {
                    @media (max-width:1440px) {
                        height: 100%;
                    }
                }

                img:first-child {
                    max-width: 370px;
                    max-height: 500px;
                    width: 31.623931623931624%;
                    @media (max-width:450px) {
                        width: 35%;
                        margin-right: .5rem;
                    }
                }

                img:nth-child(2) {
                    position: relative;
                    left: -4.375rem;
                    top: 6.25rem;
                    z-index: 500;
                    max-width: 470px;
                    max-height: 320px;
                    width: 40.170940170940171%;

                    @media (max-width:1000px) {
                        top: 4.25rem;
                    }

                    @media (max-width:700px) {
                        top: 3.25rem;
                        left: -3.375rem;
                    }

                    @media (max-width:600px) {
                        top: 2.25rem;
                    }
                    @media (max-width:450px) {
                        position: unset;
                        order: 3;
                        width: 60%;
                        margin: 0 auto;
                        margin-top: -3rem;
                        
                    }
                }

                img:nth-child(3) {
                    position: relative;
                    left: -2.5rem;
                    top: -7.05rem;
                    z-index: 499;
                    max-width: 370px;
                    max-height: 600px;
                    width: 31.623931623931624%;
                    @media (max-width:450px) {
                        position: unset;
                        width: 35%;
                        max-height: 210px;
                    }
                }

                @media only screen and (max-width: 1600px) and (min-width: 1500px) {
                    width: 90%;
                    margin: 0 auto;
                }

                @media only screen and (max-width: 1700px) and (min-width: 1600px) {
                    width: 85%;
                    margin: 0 auto;
                }

                @media only screen and (max-width: 1800px) and (min-width: 1700px) {
                    width: 80%;
                    margin: 0 auto;
                }

                @media only screen and (max-width: 1920px) and (min-width: 1800px) {
                    width: 75%;
                    margin: 0 auto;
                }
                @media (max-width:450px) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
            }

            p {
                color: $color-light;
                max-width: 48.717948717948718%;
                opacity: 0.6;

                margin: 0;
                margin-left: 6.25rem;
                padding-bottom: $space-normal;

                @media only screen and (max-width: 1600px) and (min-width: 1500px) {
                    margin-left: 10.25rem;
                }

                @media only screen and (max-width: 1700px) and (min-width: 1600px) {
                    margin-left: 11.25rem;
                }

                @media only screen and (max-width: 1800px) and (min-width: 1700px) {
                    margin-left: 14.25rem;
                }

                @media only screen and (max-width: 1920px) and (min-width: 1800px) {
                    margin-left: 15.25rem;
                }

                @media (max-width:1160px) {
                    max-width: 60%;

                    font-size: 1.4rem;
                    line-height: 1.875rem;

                    padding-bottom: 6.25rem;
                    padding-top: 1.5625rem;                   
                    margin-left: 3.25rem;
                }

                @media (max-width:900px) {
                    font-size: 1.2rem;
                    line-height: 1.575rem;

                    padding-bottom: 5rem;
                    padding-top: 1.25rem;
                    margin-left: 2.25rem;
                }

                @media (max-width:800px) {
                    font-size: 1.1rem;
                    line-height: 1.475rem;

                    padding-top: 0.9375rem;
                    padding-bottom: $space-normal;
                }

                @media (max-width:750px) {
                    max-width: 55%;
                }

                @media (max-width:700px) {
                    font-size: 1rem;
                    line-height: 1.375rem;

                    padding-bottom: 2.5rem;
                    padding-top: 0.625rem;                    
                }

                @media (max-width:600px) {
                    max-width: 58%;
                    
                    font-size: .9rem;
                    line-height: 1.275rem;

                    margin-left: 1.25rem;
                }
                @media (max-width:450px) {
                    max-width: 90%;

                    margin-left: 1.25rem;
                    font-size: 0.85rem;
                    line-height: 1.275rem;
                }
            }

            @media (max-width:1000px) {
                width: $content-width-responsive;
            }
            @media (max-width:450px) {
                padding-top: $space-small;
            }
        }
    }
}