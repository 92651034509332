$textSize46: 2.875rem; /*46px/16*/
$textSize20: 1.25em; /*20px/16*/
$textSize12: 0.75rem; /*12px/16*/
$imageWrapperWidth: 30.348795397339086659475008989572%;



.content-wrapper{
    width: 61.811111111111111111111111111111%; 
    margin-left:23.266666666666666666666666666667%;
    position: relative;
    .text-holder{
        width: 64.059690758719884933477166486875%;
        small{
            display: block;
            font-size: $textSize12;
            color: #B4B8BF;
            letter-spacing: 0;
            padding-top: 9.56rem;
        }
        h2{
            color: #FFFFFF;
            margin: 1.13rem 0;
        }
        p{
            opacity: 0.6;
            color: #FFFFFF;
            max-width: 100%;
            padding: 0.75rem 0; 
            margin:0;
        }
        .technologySmall{
            padding-top:7.5rem;
        }
    }
    .frameworks-holder{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        list-style: none;
        flex-wrap: wrap;
        max-width: 36.56rem;
        padding-top: 1.13rem;
        z-index: 1;
        li{
            width: 8.75rem;
            height: 8.75rem;
            margin: 0.155rem;
            background: rgba(33, 36, 39,0.46);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            img{
                position: relative;
                z-index: 3;
            }
        }
        li:last-child{
            margin-bottom: 7.5rem;
        }
        .invisible{
            opacity: 0;
        }
    }
    .clients-holder-width{
        max-width: 54.68rem;
    }
    .dots{
        position: absolute;
        background: url(./../../assets/mofa-pattern/Artboard.svg) repeat center center;
        z-index: 1;
        display: block;
        background-position-y: 0px;
        height: 25.63rem;
        width: 30.343%;
        max-width: 17rem;
        opacity: 0.5;
        left: -12.5rem;
        top: 19rem;
    }
}